import React, {useState} from 'react'
import {storage, firestore} from '../config/Config.js'
// import { setUserProperties } from '@firebase/analytics';

//components
import { SelectLeague } from './SelectLeague';
import { SelectTeam } from './SelectTeam';
import { SelectCut } from './SelectCut';
import { SelectSleeve } from './SelectSleeve';
import { SelectSize } from './SelectSize';
import { SelectVariant } from './SelectVariant';
import { SelectPlayer } from './SelectPlayer';
import { JerseyImage } from './JerseyImage.js';
import { MdCheckBox } from 'react-icons/md';

export class AddJersey extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            League: 'Your Custom Jersey',
            Team: 'Name+Number Print',
            Year: '22-23',
            Cut: 'Mens',
            Sleeve: 'Short',
            Size: 'M',
            Variant: 'Home',
            JerseyImgFront: null,
            JerseyImgBack: null,
            Error: '',
            LeagueOptions: [{label: 'Loading Leagues', value: 'Loading Leagues'}],
            TeamOptions: [{label: 'Loading Teams', value: 'Loading Teams'}],
            CutOptions: [{label: 'Loading Cuts', value: 'Loading Cuts'}],
            SleeveOptions: [{label: 'Loading Sleeves', value: 'Loading Sleeves'}],
            VariantOptions: [{label: 'Loading Variants', value: 'Loading Variants'}],

            SaleSet: false,
            SalePrice: 150,
            XXLChange: false,
            XXLQuantity: 0,
            XLChange: false,
            XLQuantity: 0,
            LChange: false,
            LQuantity: 0,
            MChange: false,
            MQuantity: 0,
            SChange: false,
            SQuantity: 0,
            XSChange: false,
            XSQuantity: 0,
            XXSChange: false,
            XXSQuantity: 0,
            AnyChange: false,
            AnyQuantity: 0,
    }
        this.handleLeagueSelect = this.handleLeagueSelect.bind(this);
        this.handleTeamSelect = this.handleTeamSelect.bind(this);
        this.handleJerseyImgFront = this.handleJerseyImgFront.bind(this);
        this.handleJerseyImgBack = this.handleJerseyImgBack.bind(this);
        this.handleAddJersey = this.handleAddJersey.bind(this);
        this.handleAddJersey_ = this.handleAddJersey_.bind(this);
        this.GetLeagues = this.GetLeagues.bind(this);    
        this.GetTeams = this.GetTeams.bind(this);
        this.GetCuts = this.GetCuts.bind(this);
        this.GetSleeves = this.GetSleeves.bind(this);    
        this.GetVariants = this.GetVariants.bind(this);
        
    }

    async GetLeagues() {
        const data = [];
        const Leagues = await firestore.collection('Leagues').get()
        for (var doc of Leagues.docs){
            data.push({value: doc.data().League, label: doc.data().League}); 
        }
        // console.log(data)
        this.setState({LeagueOptions: data});
      }

    async GetTeams(){
    if (this.state.League !==''){ //test for undefined props which may come from AddJersey page
        const data = [];
        const Teams = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').get()
        for (var doc of Teams.docs){
            data.push({value: doc.data().Team, label: doc.data().Team, Year: doc.data().Year})    
        }
        
        //immediately set Team based on TeamOptions
        if (data.length > 0) {
        this.setState({TeamOptions: data, Team: data[data.length-1].value, Year: data[data.length-1].Year});
        }
    }
    }

    async GetCuts() {
    const data = [];
    const Cuts = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').get()
    for (var doc of Cuts.docs){
        data.push({value: doc.data().Cut, label: doc.data().Cut}) 
    }
    // console.log(data)
    //immediately set Sleeve based on CutOptions
    if (data.length > 0) {
        this.setState({CutOptions: data, Cut: data[data.length-1].value});
    }
    }

    async GetSleeves() {
    const data = [];
    const Sleeves = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').get()
    for (var doc of Sleeves.docs){
        data.push({value: doc.data().Sleeve, label: doc.data().Sleeve, SalePrice: doc.data().SalePrice})
    }
    
    //immediately set Sleeve based on SleeveOptions
    if (data.length > 0) {
        this.setState({SleeveOptions: data, Sleeve: data[data.length-1].value, SalePrice: doc.data().SalePrice});
    }
    }

    async GetVariants() {
    const data = [];
    const Variants = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').get()
    for (var doc of Variants.docs){
        data.push({value: doc.data().Variant, label: doc.data().Variant}) 
    }

    //immediately set Sleeve based on VariantOptions options
    if (data.length > 0) {
        this.setState({VariantOptions: data, Variant: data[data.length-1].value});  
    }                  
    }
    

    handleLeagueSelect(league) {  
    //THIS CODE IS REPEATED ELSEWHERE
    //we need to query the first team in the new league collection in order to change the Team select box
    //otherwise the team from the old league will still be set and the lookup for the rest of the select boxes will fail.
    firestore.collection("Leagues").doc(league).collection("Teams")
    .orderBy("Team", "desc")
    .limit(1)
    .get().then(querySnapshot => {
    if (!querySnapshot.empty) {
        //We know there is one doc in the querySnapshot                  
            const queryDocumentSnapshot = querySnapshot.docs[0];                  
            this.setState({Team: queryDocumentSnapshot.data().Team})
    }
    });        

    // let StateCopy = this.state;
    // StateCopy.League = league;
    // this.setState({StateCopy});
    this.setState({League: league});
    }
    
    handleTeamSelect(team) {  
    // let StateCopy = this.state;
    // StateCopy.Team = team;
    // this.setState({StateCopy});
    this.setState({Team: team});
    }

    handleJerseyImgFront(e) {
        // Ensure that valid PNG or JPG files are selected for upload.
    const types = ['image/png', 'image/jpeg']
    let selectedFile = e.target.files[0];
    if (selectedFile && types.includes(selectedFile.type)){
        this.setState({JerseyImgFront: selectedFile});
        this.setState({Error: ''});
    }
    else
    {
        this.setState({JerseyImgFront: null});
        this.setState({Error: 'Please select a valid JPG or PNG file.'});
    }
    }

    handleJerseyImgBack(e) {
    // Ensure that valid PNG or JPG files are selected for upload.
    const types = ['image/png', 'image/jpeg']
    let selectedFile = e.target.files[0];
    if (selectedFile && types.includes(selectedFile.type)){
        this.setState({JerseyImgBack: selectedFile});
        this.setState({Error: ''});
    }
    else
    {
        this.setState({JerseyImgBack: null});
        this.setState({Error: 'Please select a valid JPG or PNG file.'});
    }
    }

    async handleAddJersey_() {
        var sizelabel = ''

        // ()=>{
            firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).set({
                Variant: this.state.Variant
                }).then(()=>{
                    firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').get().then(                                                
                    (DocumentSnapshot)=>{
                        // if (DocumentSnapshot.empty) {
                            // console.log(this.state.SChange)

                            if (this.state.XXSChange === 'on') {
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('XXS').set({
                                    Size: 'XXS (16)',
                                    StockQuantity: this.state.XXSQuantity});
                                }
                            if (this.state.XSChange === 'on') {
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('XS').set({
                                    Size: 'XS (18)',
                                    StockQuantity: this.state.XSQuantity});
                                }
                            if (this.state.SChange === 'on') {
                                if (this.state.Cut=='Youth') { sizelabel = 'S (20)'} else {sizelabel = 'S'}
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('S').set({
                                    Size: sizelabel,
                                    StockQuantity: this.state.SQuantity});
                                }   
                            if (this.state.MChange === 'on') {
                                if (this.state.Cut=='Youth') { sizelabel = 'M (22)'} else {sizelabel = 'M'}
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('M').set({
                                    Size: sizelabel,
                                    StockQuantity: this.state.MQuantity})
                                }
                            if (this.state.LChange === 'on') {
                                if (this.state.Cut=='Youth') { sizelabel = 'L (24)'} else {sizelabel = 'L'}
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('L').set({
                                    Size: sizelabel,
                                    StockQuantity: this.state.LQuantity})
                                }
                            if (this.state.XLChange === 'on') {
                                if (this.state.Cut=='Youth') { sizelabel = 'XL (26)'} else {sizelabel = 'XL'}
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('XL').set({
                                    Size: sizelabel,
                                    StockQuantity: this.state.XLQuantity})
                                }
                            if (this.state.XXLChange === 'on') {
                                if (this.state.Cut=='Youth') { sizelabel = '2XL (28)'} else {sizelabel = '2XL'}
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('2XL').set({
                                    Size: sizelabel,
                                    StockQuantity: this.state.XXLQuantity})
                                }
                            if (this.state.AnyChange === 'on') {
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').doc('Any').set({
                                    Size: 'Any',
                                    StockQuantity: this.state.AnyQuantity})
                                }
                            // }

                        })
                        })
                        // }


    }


    async handleAddJersey(e) {
    e.preventDefault();

    //add new jersey
    firestore.collection('Leagues').doc(this.state.League).set({ 
        League: this.state.League}).then(()=>{
            firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).set({
                Team: this.state.Team,
                Year: this.state.Year}).then(()=>{
                    firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).set({
                        Cut: this.state.Cut}).then(()=>{
                            console.log(this.state.SaleSet)
                            
                            if (this.state.SaleSet === 'on') {                                
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).set({
                                    SalePrice: this.state.SalePrice,
                                    Sleeve: this.state.Sleeve,
                                }).then(()=>this.handleAddJersey_())                            
                            }
                            else{
                                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).set({
                                    Sleeve: this.state.Sleeve,
                                }).then(()=>this.handleAddJersey_())
                            }
                            
                                                })
                                                })
                                                })
    
    
    const JerseyImgs = [this.state.JerseyImgFront, this.state.JerseyImgBack];
    const JerseySide = ['Front', 'Back']; //variables to be used for dynamic fields in firestore documents

    for (let step = 0; step < 2; step++) {
        const Img = JerseyImgs[step];
        const uploadTask = storage.ref(`jersey-images/${this.state.Year}/${Img.name}`).put(Img); //upload image function
        var JerseyUpdate = {}; //We use this object to update the firestore document. The object will contain a dynamically changing field based on the side of the jersey URL we are updating. An image for the front or back.
        

        uploadTask.on('state_changed', 
            
            snapshot=>{
            const progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            console.log(progress);
            }, //track upload progress

            Error=>{this.setState(Error, Error.message)}, //report errors

            () => storage.ref(`jersey-images/${this.state.Year}`).child(Img.name).getDownloadURL().then(URL=>{
                JerseyUpdate[`JerseyImg${JerseySide[step]}`] = URL;   //set the object to the field 'JerseyImageFront' or 'JerseyImageBack' and place the URL inside it.                 
                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).update(JerseyUpdate)      //update the field of the previosly create firestore doc              
                }).then(alert('Jersey Added')).catch(Error=>this.setState({Error: Error.messaage})) //catch any errors from image upload or firestore upload,
            )
        
        
        
        };   
        
     
        // this.setState({
        //     XXLChange: false,
        // XXLQuantity: '',
        // XLChange: false,
        // XLQuantity: '',
        // LChange: false,
        // LQuantity: '',
        // MChange: false,
        // MQuantity: '',
        // SChange: false,
        // SQuantity: '',
        // })
    
    }

    


    render() {
        return (        
            <div className='container'>
                <h2>Add Jersey</h2>
                <form onSubmit={this.handleAddJersey}> {/**/}            
                    <SelectLeague onLeagueSelect={this.handleLeagueSelect} LeagueOptions={this.state.LeagueOptions} GetLeagues={this.GetLeagues}/>
                
                    <label>or type New Year</label>
                    <input type="text" required
                    onChange={(e) => {this.setState({League: e.target.value})}} value={this.state.League}/>
                    <br/><br/>
                    <SelectTeam onTeamSelect={this.handleTeamSelect} League={this.state.League} TeamOptions={this.state.TeamOptions} GetTeams={this.GetTeams}/>
                    <label>or type New Team</label>
                    <input type="text" required
                    onChange={(e) => {this.setState({Team: e.target.value})}} value={this.state.Team}/>
                    <br/>
                    <label>Firestore Folder Name</label>
                    <input type="text" required
                    placeholder='21-22'
                    onChange={(e) => {this.setState({Year: e.target.value})}} value={this.state.Year}/>                
                    <br/><br/>
                    <label>Select Cut</label>
                    <select id='SelectCut' onChange={(e) => {this.setState({Cut: e.target.value})}}>
                        <option value='Mens'>Mens</option>
                        <option value='Womens'>Womens</option>
                        <option value='Youth'>Youth</option>                        
                        <option value='Any'>Any</option>
                    </select>                    
                    <br/><br/>
                    <label>Select Sleeve</label>
                    <select id='SelectSleeve' onChange={(e) => {this.setState({Sleeve: e.target.value})}}>
                        <option value='Short'>Short</option>
                        {/* {() => {var select = document.getElementById('SelectCut');
                                    console.log('checking');
                                    if (select){
                                        console.log('valid');
                                        if (select.value === 'Mens'){
                                            (<option value='Long'>Long</option>)
                                        }
                                    }
                                }
                            } */}
                        <option value='Long'>Long</option>                        
                        <option value='Any'>Any</option>
                    </select> 
                    <br/><br/>
                    <label>Select Variant</label>
                    <select id='SelectVariant' onChange={(e) => {this.setState({Variant: e.target.value})}}>
                        <option value='Home'>Home</option>
                        <option value='Away'>Away</option>
                        <option value='3rd'>3rd</option>
                        <option value='Any'>Any</option>
                    </select> 
                    <br/>
                    <label>or type New Variant</label>
                    <input type="text" required
                    onChange={(e) => {this.setState({Variant: e.target.value})}}
                    value={this.state.Variant}/>
                    <br/><br/>
                    {/* <SelectSize onSizeSelect={this.handleSizeSelect} onQuantityChange={this.handleQuantityChange} League={this.state.League} Team={this.state.Team} Cut={this.state.Cut} Sleeve={this.state.Sleeve} Variant={this.state.Variant}/>
                    <label>or type New Size</label>
                    <input type="text" required
                    onChange={(e) => {this.setState({Size: e.target.value})}} value={this.state.Size}/>
                    <br/><br/> */}
                    
                    {/* <label>New Stock Quantity</label>
                    <input type="text" required
                    onChange={(e) => {this.setState({StockQuantity: e.target.value})}} value={this.state.StockQuantity}/>
                    <br/><br/> */}
                    

                    {/* <div className="Jerseycontainer">  */}
                        {/* <label>Back</label>                     */}
                        {/* <JerseyImage League={this.state.League} Team={this.state.Team} Cut={this.state.Cut} Sleeve={this.state.Sleeve} Variant={this.state.Variant}/> */}
                        {/* <label>Front Thumb</label>  */}
                        {/* <br/>
                        <label>Re-use Image</label><input id='ReUseImageCheckbox' type="checkbox" onChange={(e) => {this.setState({ReUse: e.target.value}, console.log(this.state.ReUse))}}/> */}
                    {/* </div> */}

                    <label>Front Image</label>
                    <input type="file"
                    id='JerseyImgFrontfile'
                    required //{!this.state.ReUse} //{!(document.getElementById('ReUseImageCheckbox').checked)}
                    onChange={this.handleJerseyImgFront}/>
                    <br/>
                    <label>Back Image</label>
                    <input type="file"
                    id='JerseyImgBackfile'
                    required //{!this.state.ReUse} //{!(document.getElementById('ReUseImageCheckbox').checked)}
                    onChange={this.handleJerseyImgBack}/>
                    <br/><br/>


                    <input type="checkbox"                    
                    onChange={(e) => this.setState({SaleSet: e.target.value})}/>
                    <label>Sale Price</label>                    
                     <input type="number"  
                     value = {this.state.SalePrice}                  
                    onChange={(e) => this.setState({SalePrice: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({XXLChange: e.target.value})}/>
                    <label>2XL Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({XXLQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({XLChange: e.target.value})}/>
                    <label>XL Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({XLQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({LChange: e.target.value})}/>
                    <label>L Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({LQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({MChange: e.target.value})}/>
                    <label>M Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({MQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({SChange: e.target.value})}/>
                    <label>S Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({SQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({XSChange: e.target.value})}/>
                    <label>XS Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({XSQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({XXSChange: e.target.value})}/>
                    <label>XXS Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({XXSQuantity: e.target.valueAsNumber})}/>
                    <br/>

                    <input type="checkbox"                    
                    onChange={(e) => this.setState({AnyChange: e.target.value})}/>
                    <label>Any Quantity</label>                    
                     <input type="number"                    
                    onChange={(e) => this.setState({AnyQuantity: e.target.valueAsNumber})}/>
                    <br/>



                    <button>ADD</button>
                </form>
                {Error && <span>{Error}</span>}
            </div>
        )
    }
}
