import { useState } from 'react'

export const PlayerNameInput = (props) => {
    const [Name, setName] = useState('')   

    const handleChange=(e) => {  
        const s = e.target.value.replace(/\+|=|_|{|}|\[|\]|~|!|@|#|\$|%|\^|&|\*|\(|\)|\\|;|:|"|,|>|<|\?|\//gi, '')    
        // const PlayerName = s
        setName(s.toUpperCase())
        props.onPlayerNameChange(s)
        // console.log(e.target.value)
        // const value = e.target.value.replace(/\+|-/ig, '');
        // this.setState({financialGoal: value});
      }

    return (
        <div>
            <label>Name:</label>
            <input
                id= 'PlayerNameInput'
                type="text"
                pattern="[A-Z*]"
                maxLength = "12"
                value={Name}
                onChange={handleChange}/>            
        </div>
    )
}

// const PlayerNumber = (evt.target.validity.valid) ? 
//   evt.target.value : this.state.financialGoal;
//