import React from 'react'
// import { useState } from 'react'

import { SelectLeague } from './SelectLeague';
import { SelectTeam } from './SelectTeam';
import { SelectCut } from './SelectCut';
import { SelectSleeve } from './SelectSleeve';
import { SelectSize } from './SelectSize';
import { SelectVariant } from './SelectVariant';
import { SelectPlayer } from './SelectPlayer';
import { PlayerNameInput } from './PlayerNameInput';
import { PlayerNumberInput } from './PlayerNumberInput';
import { PlayerNoteInput } from './PlayerNoteInput';
import { JerseyImage } from './JerseyImage.js';
import { SearchStock } from './SearchStock.js';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { History } from 'history'


import Dialog from "@mui/material/Dialog";


import { GlobalJerseyPrice } from '../config/Config';
// import { setUserId } from '@firebase/analytics';
// import { AddToCart } from './AddToCart.js'
import {firestore} from '../config/Config.js'
import { setUserId } from '@firebase/analytics';

class CustomizeJersey extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      Customizable: true,
      CustomizeChecked: false,
      League: 'Your Custom Jersey',
      Team: 'Name+Number Print',
      Year: '',
      Cut: 'Mens',
      Sleeve: 'Any',
      Size: 'Any',
      Variant: 'Any',
      PlayerName: 'ANY NAME',
      PlayerNumber: '00',
      CustomPlayer: true,
      PlayerNote: '',
      JerseyImg: '',
      SalePrice: undefined,
      PrintOptions: '',
      QuantityInStock: {},
      styleObj: {fontFamily: 'BasicFont',  fill: 'white'}, //FontsFree-Net-Barclays-Premier-League.ttf
      NumberCSS: 'centeredBasicNumber',
      LeagueOptions: [{label: 'Loading Leagues', value: 'Loading Leagues'}],
      TeamOptions: [{label: 'Loading Teams', value: 'Loading Teams'}],
      CutOptions: [{label: 'Loading Cuts', value: 'Loading Cuts'}],
      SleeveOptions: [{label: 'Loading Sleeves', value: 'Loading Sleeves'}],
      VariantOptions: [{label: 'Loading Variants', value: 'Loading Variants'}],
      SizeOptions: [{label: 'Loading Sizes', value: 'Loading Sizes', QuantityInStock: ''}],
      CalculatedPrice: 0,
      AddtoCartDialogShow: false,
      }
    this.handleLeagueSelect = this.handleLeagueSelect.bind(this);
    this.handleTeamSelect = this.handleTeamSelect.bind(this);
    this.handleCutSelect = this.handleCutSelect.bind(this);
    this.handleSleeveSelect = this.handleSleeveSelect.bind(this);
    this.handleVariantSelect = this.handleVariantSelect.bind(this);
    this.handleSizeSelect = this.handleSizeSelect.bind(this);
    this.handlePlayerSelect = this.handlePlayerSelect.bind(this);
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.AddToCart = this.AddToCart.bind(this);
    this.handleCustomizeCheckBoxChange = this.handleCustomizeCheckBoxChange.bind(this);
    // this.onChangeRadio = this.onChangeRadio.bind(this);
    this.GetLeagues = this.GetLeagues.bind(this);    
    this.GetTeams = this.GetTeams.bind(this);
    this.GetCuts = this.GetCuts.bind(this);
    this.GetSleeves = this.GetSleeves.bind(this);    
    this.GetVariants = this.GetVariants.bind(this);
    this.GetSizes = this.GetSizes.bind(this);
    this.FindQuantityInStock = this.FindQuantityInStock.bind(this);
    // this.CalculatePrice = this.CalculatePrice.bind(this);
    
    // this.fontSwitch =  this.fontSwitch.bind(this);
  }

  async CustomizeState() {
    //THIS MAY BE BAD PRACTICE. WE ARE SETTING STATE FROM THE URL.
    ///THEREFORE WE HAVE A DUPLICATE INSTEAD OF SINGLE SOURCE OF TRUTH

    if (this.props.location.search != '') {
      const params = new URLSearchParams(this.props.location.search);

      this.setState({CustomizeChecked: true})

      await this.GetTeams() //SHOULD THIS BE THIS.GETYEAR???
      this.setState({League: params.get('Year').replace('%20',' ')})

      await this.GetTeams()
      this.setState({Team: params.get('Team').replace('%20',' ')})
      
      await this.GetCuts()
      this.setState({Cut: params.get('Cut')})
      
      await this.GetSleeves()
      this.setState({Sleeve: params.get('Sleeve')})
      
      await this.GetVariants()
      this.setState({Variant: params.get('Variant')})
      
      await this.GetSizes()
      this.setState({Size: params.get('Size')})
    }

    
  }

  async componentDidMount() {
    this.CustomizeState();      
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.CustomizeState();  
    }    
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 
  //THE FOLLOWING HANDLE CHANGE EVENTS MUST UPDATE ALL PARTS OF THE STATE AT ONCE TO ENSURE ALL COMPONENTS ARE RE-RENDERED
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 

  async handleLeagueSelect(league) {  
    //we need to query the first team in the new league collection in order to change the Team select box 
    ///otherwise the team from the old league will still be set and the lookup for the rest of the select boxes will fail. 

    await firestore.collection("Leagues").doc(league).collection("Teams")
          .orderBy("Team", "desc")
          .limit(1)
          .get().then(querySnapshot => {
            if (!querySnapshot.empty) {
                //We know there is one doc in the querySnapshot                  
                  const queryDocumentSnapshot = querySnapshot.docs[0];                  
                  this.setState({Team: queryDocumentSnapshot.data().Team})
                  this.setState(this.fontSwitch(queryDocumentSnapshot.data().Team))
            }
          });

    // let StateCopy = this.state;
    // StateCopy.League = league;
    // this.setState({StateCopy});

    this.setState({League: league});      // , Cut: 'Mens'})

    ////////////////////////////////////////////////////////////////////////////
    //FOR TEMPORARY USE
    // if (league !== 'Your Custom Jersey'){
    //   this.setState({CustomizeChecked: false, PlayerName: '', PlayerNumber:''})
    // }
    ////////////////////////////////////////////////////////////////////////////
  }

  async handleTeamSelect(team) { 

    let StateCopy = this.state;
    StateCopy.Team = team;
    // console.log(team)
    
    //set the year based on the selected team
    for (var option in this.state.TeamOptions) {
      // console.log(option, team)
      if (team === option.team) {
        StateCopy.Year = option.year
      }
    }

    if ((team == "Badges and Patches") || (team == "Inter Miami")) { //these are not customizable options so take them off screen
      this.setState({PlayerName: '', PlayerNumber: '', PrintOptions: '', Customizable: false, CustomizeChecked: false})
    }
    else{
      this.setState({Customizable: true})  
    }

    // StateCopy.Year = year;
    StateCopy.styleObj = this.fontSwitch(team).styleObj;
    StateCopy.NumberCSS = this.fontSwitch(team).NumberCSS;
    // StateCopy.Cut = 'Mens';
    this.setState({StateCopy});
    // console.log(this.state.styleObj)
    // console.log(this.state.Team)
  }

  async handleCutSelect(cut) {  
    let StateCopy = this.state;
    StateCopy.Cut = cut;
    this.setState({StateCopy});
  }

  async handleSleeveSelect(sleeve) {
    // console.log('sleeveselect')
      let StateCopy = this.state;
      StateCopy.Sleeve = sleeve;

      //check to see if there are sale options for the selected sleeve and item
      for (var option of this.state.SleeveOptions) {
        if (sleeve === option.value) {
          StateCopy.SalePrice = option.SalePrice
        }
      }

      this.setState({StateCopy});
      // console.log(this.state); 

      
  }

  async handleVariantSelect(variant) {       
      let StateCopy = this.state;
      StateCopy.Variant = variant;
      this.setState({StateCopy});
      // console.log(this.state)
  }

  async handleSizeSelect(size) {  
    let StateCopy = this.state;
    StateCopy.Size = size;
    this.setState({StateCopy});
    // console.log(this.state)
    // let StateCopy = this.state;
    // this.setState({
    //   ...StateCopy,
    //   Size: size
    // })
  }

  // handlePlayerSelect(playerName, playerNumber ) {  
  //   console.log(playerName)
  //   let StateCopy = this.state;
  //   StateCopy.PlayerName = playerName;
  //   StateCopy.PlayerNumber = playerNumber;
  //   if (playerName == 'ANY NAME') {
  //     StateCopy.CustomPlayer = true;
  //   }
  //   else {
  //     StateCopy.CustomPlayer = false;
  //   }
  //   this.setState({StateCopy});
  // }

  handlePlayerChange=(PlayerNameValue) => {
    let StateCopy = this.state;
    StateCopy.PlayerName = PlayerNameValue.toUpperCase();
    this.setState({StateCopy});
  }

  handleNumberChange=(PlayerNumberValue) => {        
    let StateCopy = this.state;
    StateCopy.PlayerNumber = PlayerNumberValue;
    this.setState({StateCopy});
  }

  handlePlayerNoteChange=(PlayerNote) => {        
    let StateCopy = this.state;
    StateCopy.PlayerNote = PlayerNote;
    this.setState({StateCopy});
  }

  

  async AddToCart(uid, Jersey, Price) {
    // console.log(uid);
    if (uid !== null) {
      
      // console.log(Jersey);
      // if (firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).exists) {
      //   firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).update({Quantity: Quantity+=1})
      // }
      // else {
        if (this.state.CustomizeChecked == false) {
          Jersey.PlayerName = '';
          Jersey.PlayerNumber = '';
        }

        const doc = await firestore.collection('Leagues').doc(Jersey.League).collection('Teams').doc(Jersey.Team).collection('Cuts').doc(Jersey.Cut).collection('Sleeves').doc(Jersey.Sleeve).collection('Variants').doc(Jersey.Variant).get();
        // console.log(doc.data());
        const JerseyImgFront = doc.data().JerseyImgFront;

        // console.log(Jersey)

        firestore.collection('Users').doc(uid).set({
          UID: uid
        }).then(
          firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.League+Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).set({            
            League: Jersey.League,
            Team: Jersey.Team,
            Year: Jersey.Year,
            Cut: Jersey.Cut,
            Sleeve: Jersey.Sleeve,
            Variant: Jersey.Variant,
            Size: Jersey.Size,
            PlayerName: Jersey.PlayerName,
            PlayerNumber: Jersey.PlayerNumber,
            Quantity: 1,
            Price: Price,  
            JerseyImgFront: JerseyImgFront,
            PlayerNote: Jersey.PlayerNote,
            PrintOptions: Jersey.PrintOptions,
          }) 
        )
    // }
      }

      
    // else{
      //props.history.push('/login');
    // }
    
  }

  async handleAddToCart() {

    //check to ensure that we are not trying to add a custom name and number print order to the cart without first enabling the customize checkbox
    if ((this.state.Team == 'Name+Number Print') && (this.state.CustomizeChecked == false)) {

      // alert('Please click the Customize It! box and enter a custom name and number')
      var checkbox = document.getElementById('CustomizeItCheckBox')
      if (checkbox.checked == false) {
        checkbox.click()
      }
      // this.setState(CustomizeChecked) = true
    }

    function ValidateCustomization(state) {
      var result = true;
      console.log(state.PlayerName)      
      if (((state.PlayerName == 'ANY NAME') || (state.PlayerName == '')) && ((state.CustomizeChecked) || (state.Team == 'Name+Number Print'))) {
        alert('Please enter a custom name.')
        result = false
      }
      else {
        if (((state.PlayerNumber == '00') || (state.PlayerNumber == '')) && ((state.CustomizeChecked) || (state.Team == 'Name+Number Print'))) { 
          alert('Please enter a custom number.') 
          result = false
        }
      } 
      console.log(result)       
      return result
    }

    if (this.props.user == null) {
      await this.props.AnonSignIn().then( (res) => { //ensure that we try add to cart only after we verify that a user has ebenn signed in anonymously or otherwise
        if (ValidateCustomization(this.state)) {
          this.AddToCart(res.user.uid, this.state, this.CalculatePrice());
          if (this.state.CustomizeChecked ) {
            alert('By clicking OK you acknowledge that jersey printing is done by a 3rd party and that SoccerExpressionz cannot be held liable for any misprints or damage to jerseys as a result thereof.')
          }  
          this.setState({AddtoCartDialogShow: true})          
        }        
      }
      );  
    }
    else 
    {
      console.log('error here??')

      if (ValidateCustomization(this.state)) {
        this.AddToCart(this.props.user.uid, this.state, this.CalculatePrice());
        if (this.state.CustomizeChecked ) {
          alert('By clicking OK you acknowledge that jersey printing is done by a 3rd party and that SoccerExpressionz cannot be held liable for any misprints or damage to jerseys as a result thereof.')
        }  
        this.setState({AddtoCartDialogShow: true})          
      } 

    }

      

    // try {
    //   await this.props.AnonSignIn();  
    // }
    // catch(err) {
    //   console.log(err)
    // }
    // finally {

    //   if ((this.state.PlayerName == 'ANY NAME') && (this.state.CustomizeChecked)) {
    //     alert('Please enter a custom name.')
    //   }
    //   else {
    //     if ((this.state.PlayerNumber == '00') && (this.state.CustomizeChecked)) { 
    //       alert('Please enter a custom number.') 
    //     }
    //     else {
    //       this.AddToCart(this.props.user.uid, this.state, this.CalculatePrice());
    //       if (this.state.CustomizeChecked ) {
    //         alert('By clicking OK you acknowledge that jersey printing is done by a 3rd party and that SoccerExpressionz cannot be held liable for any misprints or damage to jerseys as a result thereof.')
    //       }

    //       this.setState({AddtoCartDialogShow: true})
          
    //     }
    //   }
    // }
    
  }

  CalculatePrice() {

    //Flat fee for printing on custom jerseys
    var ShippingCost=0;
    var JerseyPrice=0;
    var PrintPrice=0;

    //search to see if a SalePrice is set.
    //if not, then set the default values

    // if (this.state.League.includes('Stock') || this.state.Team == 'Custom Order')  {
    if (this.state.SalePrice != null)  {
      JerseyPrice = this.state.SalePrice

      // console.log(this.state)
      // firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).get()
      // .then(
      //   (doc) =>{if (doc.data() != undefined){
      //     console.log(doc.data())
      //     JerseyPrice = doc.data().SalePrice;
      //   }
      //   }
      //   ) 

      // const doc = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).get()
      // if (doc.data() != undefined){
      //     console.log(doc.data())
      //     JerseyPrice = doc.data().SalePrice;
      // }

    }
    else {
      if (this.state.League === 'Your Custom Jersey'){
        return (GlobalJerseyPrice.Custom);
      }

          

      if (this.state.Cut === 'Youth') {
        JerseyPrice=GlobalJerseyPrice.Youth;
      }
      else if (this.state.Cut === 'Womens') {
        JerseyPrice=GlobalJerseyPrice.Womens;
      }
      else if (this.state.Cut === 'Mens') {
        if (this.state.Sleeve === 'Short') {
          JerseyPrice=GlobalJerseyPrice.MensShort;
          }
          else {
            JerseyPrice=GlobalJerseyPrice.MensLong;
          }
      }    
    }

    if ((!this.state.CustomizeChecked) || (this.state.PlayerName === '')) {      
      PrintPrice=0;
    }
    else {
      PrintPrice=100;
      // if (this.state.PrintOptions === 'LeagueCupFont'){
      //   PrintPrice=100;}
      // if (this.state.PrintOptions === 'BasicFont'){
      //   PrintPrice=75;}
    }

    // console.log(this.state.QuantityInStock);
    if (this.state.QuantityInStock === 0) {
      ShippingCost=70;
    }
    else {
      ShippingCost=0;
    }

    return (JerseyPrice+PrintPrice+ShippingCost); 

    // console.log(JerseyPrice+PrintPrice+ShippingCost)
    // this.setState({CalculatedPrice: JerseyPrice+PrintPrice+ShippingCost})
  }

  handleCustomizeCheckBoxChange() {
    this.setState({CustomizeChecked: !this.state.CustomizeChecked})  
    if (this.state.CustomizeChecked) {
      this.setState({PlayerName: '', PlayerNumber: '', PrintOptions: ''})
      
    }
    else {
      this.setState({PlayerName: 'ANY NAME', PlayerNumber: '00', PrintOptions: 'LeagueCupFont'})
    }
  }  

  handlePlayerSelect=(PlayerNameValue, PlayerNumberValue) => {   
    this.setState({PlayerName: PlayerNameValue,  PlayerNumber: PlayerNumberValue})
    if (PlayerNameValue == 'ANY NAME') {
      this.setState({CustomPlayer: true});
    }
    else {
      this.setState({CustomPlayer: false});
    }
  }

  fontSwitch(param) {
    // console.log(param)
    switch(param) {
      case 'Juventus':
        return {styleObj: {fontFamily: 'SerieAFont', fill: 'black', color: 'black'}, NumberCSS: 'centeredLeagueCupNumber'}
      case 'PSG':
        return {styleObj: {fontFamily: 'Ligue1Font', fill: 'white', color: 'white'}, NumberCSS: 'centeredBasicNumber'}
      case 'Real Madrid':
        return {styleObj: {fontFamily: 'RealMadridCupFont', fill: '#4b5fa8', color: '#4b5fa8'}, NumberCSS: 'centeredLeagueCupNumber'} //'#4b5fa8'
      case 'Barcelona':
        return {styleObj: {fontFamily: 'LaLigaFont', fill: 'yellow', color: 'yellow'}, NumberCSS: 'centeredBasicNumber'}
      case 'Manchester City':
        return {styleObj: {fontFamily: 'ManCityFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredLeagueCupNumber'}
      case 'Manchester United':
        return {styleObj: {fontFamily: 'ManUtdFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredLeagueCupNumber'}
      case 'Liverpool':
        return {styleObj: {fontFamily: 'LiverpoolCupFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredBasicNumber'}
      case 'Chelsea':
        return {styleObj: {fontFamily: 'ChelseaCupFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredBasicNumber'}
      case 'Arsenal':
        return {styleObj: {fontFamily: 'ArsenalFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredBasicNumber'}
      case 'Bayern Munich':
        return {styleObj: {fontFamily: 'BundesligaFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredLeagueCupNumber'}            
      
      default:
        return {styleObj: {fontFamily: 'BasicFont', fill: 'white', color: 'white'}, NumberCSS: 'centeredBasicNumber'}
    }
  }

  // onChangeRadio(e) {
  //   this.setState({PrintOptions: e.target.value});
  //   if (e.target.value === 'BasicFont') {      
  //     this.setState({styleObj: {fontFamily: 'BasicFont', fill: 'white'}, NumberCSS: 'centeredBasicNumber'});
  //   }
  //   if (e.target.value === 'LeagueCupFont') {
  //     this.setState(this.fontSwitch(this.state.Team)); 
  //   } 
  // }

  async GetLeagues() {
    const data = [];
    const Leagues = await firestore.collection('Leagues').get()
    for (var doc of Leagues.docs){
        data.push({value: doc.data().League, label: doc.data().League}); 
    }
    // console.log(data, 'leagues')
    this.setState({LeagueOptions: data});
  } 

  async GetTeams(){
    if (this.state.League !==''){ //test for undefined props which may come from AddJersey page
        const data = [];
        const Teams = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').get()
        for (var doc of Teams.docs){
            data.push({value: doc.data().Team, label: doc.data().Team, Year: doc.data().Year})    
        }
        
      
      //immediately set TeamOptions
      if (data.length > 0) {
        
        //and set Team if it does not come from location.state, i.e from a customize link
        // console.log(this.state.Team, data )
        if (!data.some(d => d.value === this.state.Team)) {
          this.setState({Team: data[data.length-1].value, TeamOptions: data, Year: data[data.length-1].Year})
        }
        else {
          this.setState({TeamOptions: data, Year: data[data.length-1].Year});
        }
      }
    }
  }

  async GetCuts() {
    const data = [];
    const Cuts = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').get()
    for (var doc of Cuts.docs){
        data.push({value: doc.data().Cut, label: doc.data().Cut}) 
    }
    // console.log(data)
    //immediately set CutOptions
    if (data.length > 0) {
      
      //and set Cut if it does not come from location.state, i.e from a customize link
      if (!data.some(d => d.value === this.state.Cut)) {
        this.setState({Cut: data[data.length-1].value, CutOptions: data})
      }
      else {
        this.setState({CutOptions: data});
      }
    }
  }

  async GetSleeves() {
    const data = [];
    const Sleeves = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').get()
    for (var doc of Sleeves.docs){
        data.push({value: doc.data().Sleeve, label: doc.data().Sleeve, SalePrice: doc.data().SalePrice})
    }
    
    //immediately set SleeveOptions
    if (data.length > 0) {
      
      //reset the Sleeve if the currently selected Sleeve is not in VariantOptions   
      // console.log(this.state.Sleeve, data )   
      if (!data.some(d => d.value === this.state.Sleeve)) {
        this.setState({Sleeve: data[data.length-1].value, SleeveOptions: data, SalePrice: data[data.length-1].SalePrice})
      }
      else {
        this.setState({SleeveOptions: data, SalePrice: data[data.length-1].SalePrice}) 
      }
    }
  }

  async GetVariants() {
    const data = [];
    const Variants = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').get()
    for (var doc of Variants.docs){
        data.push({value: doc.data().Variant, label: doc.data().Variant}) 
    }

    //immediately set VariantOptions options
    if (data.length > 0) {
      //reset the Variant if the currently selected variant is not in VariantOptions    
      // console.log(this.state.Variant, data )   
      if (!data.some(d => d.value === this.state.Variant)) {
        this.setState({Variant: data[data.length-1].value, VariantOptions: data})
      }  
      else {
        this.setState({VariantOptions: data})  
      }
    }                  
  }

  async GetSizes() {
    const data = [];
    // console.log(this.state.Team, this.state.Cut, this.state.Variant)
    const Sizes = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Cuts').doc(this.state.Cut).collection('Sleeves').doc(this.state.Sleeve).collection('Variants').doc(this.state.Variant).collection('Sizes').get()
    for (var doc of Sizes.docs){
        data.push({value: doc.data().Size, label: doc.data().Size, QuantityInStock: doc.data().StockQuantity});
    }
    
    //immediately set SizeOptions options
    if (data.length > 0) {     
      
      //reset the Size if the currently selected Size is not in VariantOptions      
      if (!data.some(d => d.value === this.state.Size)) {
          this.setState({Size: data[data.length-1].value, SizeOptions: data})  //if the selected size was present in the sizeoptions array we would not be making any changes to the selected size
      }
      else {
        this.setState({SizeOptions: data});
      }
      // else {
      //   this.setState({Size: this.props.location.Size})
      // }
    }    
    // this.setState({selectValue: data.at(-1).value});
    // this.setState({selectValue: data.at(-1).value}, {Options: data});
    
  }

  FindQuantityInStock() {
    // console.log(this.state.SizeOptions)
    for (var option of this.state.SizeOptions) {
        // if (option.value === this.state.selectValue) {
          
        if (option.value === this.state.Size) {
          // console.log(this.state.Size)
          return option.QuantityInStock;
        }
    }
    //if this.state.size does not match anything in the SizeOptions the above search will fail.
    // This most likely means we have reset the SizeOptions but the State.Size has not updated to match yet.
    // Thus we should automatically return the first option in the array to match what will be on screen
    return this.state.SizeOptions[0].QuantityInStock 
    // return 'Loading stock values...';
}

// lgdoubleplay4g
// naruto07

 
  render() {

    // <div onChange={this.onChangeRadio}>
    //             <input type="radio" value="BasicFont" /* value="PremierLeagueFont"*/ name="Font"/> Basic $75TTD &nbsp;
    //             {/* <input type="radio" value="CupFont" name="Font" /> Cup $100TTD &nbsp; */}
    //             <input type="radio" value="LeagueCupFont" /* value="PremierLeagueFont"*/ name="Font" defaultChecked/> League/Cup $100TTD
    //           </div>

    this.state.Team == 'Name+Number Print' ?
      window.history.replaceState(null, 'Soccer Expressionz', `/`)
    :
      window.history.replaceState(null, 'Soccer Expressionz', `/?Year=${this.state.League}&Team=${this.state.Team}&Cut=${this.state.Cut}&Sleeve=${this.state.Sleeve}&Variant=${this.state.Variant}&Size=${this.state.Size}`)

    const closeDialog = () => {
      this.setState({AddtoCartDialogShow: false});
    };

    const openDialog = () => {
      this.setState({AddtoCartDialogShow: true});
    }; 

    const AddtoCartDialog = this.state.AddtoCartDialogShow
      &&
      <Dialog onClose = {closeDialog} open = {openDialog}>      
      <br/>
      <center>
        A new item was just added to your cart. {" "}
      </center>
        <br/>
        <div>
        <center>
            <button className="w3-button w3-blue" onClick = {() => (
              this.setState({AddtoCartDialogShow: false}),
              this.props.history.push('/Cart')
              )}>
              View Cart and Checkout
            </button>
            
            <button className="w3-button w3-yellow" onClick = {closeDialog}>
              Continue Shopping
            </button>
        </center>
        </div>
        <br/>
      </Dialog>


    const CustomizeOptions = this.state.CustomizeChecked 
            ?
            <>
              +100TTD
              <div>                
                <SelectPlayer onPlayerSelect={this.handlePlayerSelect} League={this.state.League} Team={this.state.Team} />                
                {this.state.CustomPlayer &&
                  <>
                    <PlayerNameInput onPlayerNameChange={this.handlePlayerChange}/>
                    <PlayerNumberInput onPlayerNumberChange={this.handleNumberChange}/>
                  </>                
                }
                <PlayerNoteInput onPlayerNoteChange={this.handlePlayerNoteChange}/>
              </div>
            </>

              ////////////////////////////////////////////////////////////////////////////
              //FOR TEMPORARY USE
              // <div>
              //   <label className='notice'>CUSTOMIZATION OPTIONS COMING SOON!!</label>
              // </div>
              ////////////////////////////////////////////////////////////////////////////
            :
            null;            
            

    return (    
      
        // <Router> 
          <div>  
            <a href='/Search' className='button'>VIEW ALL IN STOCK ITEMS</a>

            <div className="container">            
              <SelectLeague onLeagueSelect={this.handleLeagueSelect} LeagueOptions={this.state.LeagueOptions} GetLeagues={this.GetLeagues} value={this.state.League}/>
              <SelectTeam onTeamSelect={this.handleTeamSelect} League={this.state.League} TeamOptions={this.state.TeamOptions} GetTeams={this.GetTeams} value={this.state.Team}/>
              <SelectCut onCutSelect={this.handleCutSelect} League={this.state.League} Team={this.state.Team} CutOptions={this.state.CutOptions} GetCuts={this.GetCuts} value={this.state.Cut}/>
              <SelectSleeve onSleeveSelect={this.handleSleeveSelect} League={this.state.League} Team={this.state.Team} Cut={this.state.Cut} SleeveOptions={this.state.SleeveOptions} GetSleeves={this.GetSleeves} value={this.state.Sleeve}/>
              <SelectVariant onVariantSelect={this.handleVariantSelect} League={this.state.League} Team={this.state.Team} Cut={this.state.Cut} Sleeve={this.state.Sleeve} VariantOptions={this.state.VariantOptions} GetVariants={this.GetVariants} value={this.state.Variant}/>
              <SelectSize onSizeSelect={this.handleSizeSelect} League={this.state.League} Team={this.state.Team} Cut={this.state.Cut} Sleeve={this.state.Sleeve} Variant={this.state.Variant} SizeOptions={this.state.SizeOptions} GetSizes={this.GetSizes} FindQuantityInStock={this.FindQuantityInStock} value={this.state.Size}/>
  
              <div>
                  <label>Customize It!</label>
                  <input 
                  id='CustomizeItCheckBox'
                  type="checkbox" 
                  checked={ this.state.CustomizeChecked } 
                  disabled={ !this.state.Customizable }
                  onChange={ this.handleCustomizeCheckBoxChange } />
              </div>
              {CustomizeOptions}             
            </div>

            <div className="Jerseycontainer">            
            <JerseyImage League={this.state.League} Team={this.state.Team} Cut={this.state.Cut} Sleeve={this.state.Sleeve} Variant={this.state.Variant} PlayerName={this.state.PlayerName} PlayerNumber={this.state.PlayerNumber} styleObj={this.state.styleObj} NumberCSS={this.state.NumberCSS}/>
            </div>

            <button onClick={this.handleAddToCart}>ADD TO CART TT${this.CalculatePrice()}</button>
            {AddtoCartDialog}

            {/* {this.props.user ?
            <button onClick={this.handleAddToCart}>ADD TO CART TT${this.CalculatePrice()}</button>  
            :
            <a href='/SignIn' className='button'>SIGN IN <br/> TO ADD TO CART TT${this.CalculatePrice()}</a>
            } */}
            
        </div>
      // </Router>
      
    )
  }
}

export default withRouter(CustomizeJersey);
