import { useState } from 'react'

export const PlayerNumberInput = (props) => {
    const [Number, setPlayerNumber] = useState('')

    const handleChange=(e) => { //this.setState({financialGoal: event.target.value.replace(/\D/,'')})   
        const n =  e.target.value.replace(/\D/,'')  
        // PlayerNumber = n 
        setPlayerNumber(n)
        props.onPlayerNumberChange(n)
        // setPlayerNumber(e.target.value.replace(/\+|-/ig, ''))
        // console.log(e.target.value)
        
      }

    // const PlayerNumberValidate = (e) =>{
    //      (e.target.validity.valid) ? 
    //     e.target.value : this.state.PlayerNumberValidate;
    // }

    return (
        <div>
            <label>Number:</label>
            <input
                id= 'PlayerNumberInput'
                type="text"
                pattern="[0-9*]"
                maxLength = "2"
                value={Number}
                onChange={handleChange}/>
            
        </div>
    )
}


