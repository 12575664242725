import { useState } from 'react'
import { DebounceInput } from 'react-debounce-input'

export const PlayerNoteInput = (props) => {
    const [Note, setNote] = useState('')

    const handleChange=(e) => { //this.setState({financialGoal: event.target.value.replace(/\D/,'')})   
         const s =  e.target.value 
        // PlayerNumber = n 
        setNote(s)
        props.onPlayerNoteChange(s)
        // setPlayerNumber(e.target.value.replace(/\+|-/ig, ''))
        // console.log(e.target.value)
        
      }

    return (
        <div>
            <label>Note:</label>
            <DebounceInput
                id= 'PlayerNoteInput'
                className="TextInput"
                placeholder="Note to seller. Request different fonts or leave other requests here..."
                minLength={1}
                maxLength = {255}
                debounceTimeout={500}
                onChange={handleChange}
                style={{ width: "500px" }}
                />
            {/* <input
                id= 'PlayerNoteInput'
                type="text"
                maxLength = "255"
                value={Note}
                style={{ width: "500px" }}
                onChange={handleChange}/> */}
            
        </div>
    )
}


