import React from 'react'
import { useState } from 'react'
import {firestore, Timestamp, GlobalJerseyPrice} from '../config/Config.js'
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {StockItem} from './StockItem.js'

import Dialog from "@mui/material/Dialog";
import { DebounceInput } from 'react-debounce-input'

//icons
import { MdRemoveShoppingCart, MdTungsten } from "react-icons/md"
import { RiEmotionSadLine } from "react-icons/ri"
import {RiDeleteBin5Line} from 'react-icons/ri'
import { FaCartPlus } from "react-icons/fa"

export class SearchStock extends React.Component {
    constructor(props) {
        super(props);
        this.InitialFilters = ['Mens', 'Womens', 'Youth', '18-19', '19-20', '20-21', '21-22', '22-23', '23-24',
         'XXS (16)', 'XS (18)', 'S (20)', 'M (22)', 'L (24)', 'XL (26)', '2XL (28)',
         'S', 'M', 'L', 'XL', '2XL',
          ''];//inclde '' in order to match all jerseys at first. Initially the Stock array with Year fields containing ''.
        this.state = {DeliveryChecked: false,
            // CustomizeChecked: false,           
            Stock:[],            
            // EstimatedDelivery: {},
            MensFilterChecked: true,
            WomensFilterChecked: true,
            YouthFilterChecked: true,
            OldFilterChecked: true,
            SFilterChecked: true,
            MFilterChecked: true,
            LFilterChecked: true,
            XLFilterChecked: true,
            XXLFilterChecked: true,
            Filters: this.InitialFilters,
            FilterTeam: [''],
            AddtoCartDialogShow: false,
          };
        this.GetStock = this.GetStock.bind(this);
        this.handleFilterCheckBoxChange = this.handleFilterCheckBoxChange.bind(this);
    }

    async CustomizeState() {      
      //THIS MAY BE BAD PRACTICE. WE ARE SETTING STATE FROM THE URL.
      ///THEREFORE WE HAVE A DUPLICATE INSTEAD OF SINGLE SOURCE OF TRUTH
      if (this.props.location.search != '') {
        const params = new URLSearchParams(this.props.location.search);
        // await this.GetTeams()
        // this.setState({Team: params.get('Team').replace('%20',' ')})
        var Filters = ['','18-19','19-20','20-21','21-22','22-23', '23-24'] //['']

        //values returned from params.get() are strings, not booleans
        if (params.get('Mens') == 'true') {
          Filters.push('Mens') }
        if (params.get('Womens') == 'true') {
          Filters.push('Womens') }
        if (params.get('Youth') == 'true') {
          Filters.push('Youth') }
        // if (this.state.OldFilterChecked == true) {
        //   Filters.push('18-19','19-20','20-21','21-22','22-23') 
        // }
        // else{
        //   Filters.push('22-23')
        // }
        if (params.get('S') == 'true') {
          Filters.push('S', 'XXS (16)', 'XS (18)', 'S (20)') }
        if (params.get('M') == 'true') {
          Filters.push('M', 'M (22)') }
        if (params.get('L') == 'true') {
          Filters.push('L', 'L (24)') }
        if (params.get('XL') == 'true') {
          Filters.push('XL', 'XL (26)') }
        if (params.get('2XL') == 'true') {
          Filters.push('2XL', '2XL (28)') } 

        //we use JSON.parse because the values returned from params.get() are strings, not booleans
        this.setState({Filters: Filters,
          FilterTeam: [params.get('Team')],
          MensFilterChecked: JSON.parse(params.get('Mens')),
          WomensFilterChecked: JSON.parse(params.get('Womens')),
          YouthFilterChecked: JSON.parse(params.get('Youth')), 
          SFilterChecked: JSON.parse(params.get('S')),
          MFilterChecked: JSON.parse(params.get('M')), 
          LFilterChecked: JSON.parse(params.get('L')),
          XLFilterChecked: JSON.parse(params.get('XL')),
          XXLFilterChecked: JSON.parse(params.get('2XL'))})
      }
  
      
    }

    async GetStock() {    
        var ItemsArray = [];
      
        const query = firestore.collectionGroup('Sizes')
                                .where('StockQuantity', '>', 0)
                                // .limit(25)
                                .get()
                                .then(function(querySnapshot) {
                                  querySnapshot.forEach(function(doc) {
                                    // console.log(doc.id, " => ", doc.data());

                                    
                                    
                                    var Jersey = {League: doc.ref.parent.parent.parent.parent.parent.parent.parent.parent.parent.parent.id,
                                    Team: doc.ref.parent.parent.parent.parent.parent.parent.parent.parent.id,
                                    Year: '',
                                    Cut: doc.ref.parent.parent.parent.parent.parent.parent.id,
                                    Sleeve: doc.ref.parent.parent.parent.parent.id,
                                    Variant: doc.ref.parent.parent.id,
                                    Size: doc.data().Size,
                                    Name: '',
                                    Number: '',
                                    // JerseyImgFront: '',
                                    Price: 0,
                                    quantity: doc.data().StockQuantity}                                      

                                    // doc.ref.parent.parent.get().then( parentdoc => {Jersey.JerseyImgFront = parentdoc.data().JerseyImgFront})
                                    // .then( () => {
                                    // console.log('image', Jersey.JerseyImgFront);

                                    //search to see if a SalePrice is set.
                                    //if not, then set the default values
                                    firestore.collection('Leagues').doc(Jersey.League).collection('Teams').doc(Jersey.Team).collection('Cuts').doc(Jersey.Cut).collection('Sleeves').doc(Jersey.Sleeve).get().then(
                                      (doc) =>{Jersey.Price = doc.data().SalePrice}).then(
                                        () => {if (Jersey.Price == null) {
                                          if (Jersey.League === 'Your Custom Jersey'){
                                                Jersey.Price = GlobalJerseyPrice.Custom;
                                              }
                                          
                                              if (Jersey.Cut === 'Youth') {
                                                Jersey.Price = GlobalJerseyPrice.Youth;
                                              }
                                              else if (Jersey.Cut === 'Womens') {
                                                Jersey.Price = GlobalJerseyPrice.Womens;
                                              }
                                              else if (Jersey.Cut === 'Mens') {
                                                if (Jersey.Sleeve === 'Short') {
                                                  Jersey.Price = GlobalJerseyPrice.MensShort;
                                                  }
                                                  else {
                                                    Jersey.Price = GlobalJerseyPrice.MensLong;
                                                  }
                                              }  
                                        }}    
                                      )

                                                                        
                                    // if (Jersey.League.includes('Stock')){
                                    //   firestore.collection('Leagues').doc(Jersey.League).collection('Teams').doc(Jersey.Team).collection('Cuts').doc(Jersey.Cut).collection('Sleeves').doc(Jersey.Sleeve).get().then(
                                    //     (doc) =>{Jersey.Price = doc.data().SalePrice}) 
                                    // }
                                    // else {
                                    //   if (Jersey.League === 'Your Custom Jersey'){
                                    //     Jersey.Price = 150;
                                    //   }
                                  
                                    //   if (Jersey.Cut === 'Youth') {
                                    //     Jersey.Price = 300;
                                    //   }
                                    //   else if (Jersey.Cut === 'Womens') {
                                    //     Jersey.Price = 350;
                                    //   }
                                    //   else if (Jersey.Cut === 'Mens') {
                                    //     if (Jersey.Sleeve === 'Short') {
                                    //       Jersey.Price = 350;
                                    //       }
                                    //       else {
                                    //         Jersey.Price = 400;
                                    //       }
                                    //   }
                                    // }

                                   firestore.collection('Leagues').doc(Jersey.League).collection('Teams').doc(Jersey.Team).get().then(
                                     (doc) =>{
                                       Jersey.Year = doc.data().Year
                                        }).then(
                                                ItemsArray.push(Jersey)
                                                )
                                    
                                  //  })
                                    
                                  });
                                 })
                                 .catch(function(error) {
                                   console.log("Error getting documents: ", error);
                                  })
                                  .then(() => {

        this.setState({Stock: ItemsArray})
        // this.state.Stock.forEach(function(item) {
        //   console.log(item);
        //   })
        });
        // const Stock = await firestore.collection('Leagues').doc(this.props.user.uid).collection('Cart').get();
        // for (var doc of Cart.docs){
        //     this.setState({Items: [...this.state.Stock, doc.data()]});
        // }
        

    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // await this.GetStock();  
            this.CustomizeState();   
        }
    }

    async componentDidMount() {
        await this.GetStock();   
        this.CustomizeState();       
    }

    async handleAddToCart(Jersey) {

      if (this.props.user == null) {
        await this.props.AnonSignIn().then( (res) => { 
          // console.log(res.user.uid)
          this.AddToCart(res.user.uid, Jersey);
          this.setState({AddtoCartDialogShow: true})
        })
      }
      else
      {
        this.AddToCart(this.props.user.uid, Jersey);
        this.setState({AddtoCartDialogShow: true})
      }
      
    }      

    async AddToCart(uid, Jersey) {
        // console.log(uid);
        if (uid !== null) {
          
          // console.log(Jersey);
          // if (firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).exists) {
          //   firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).update({Quantity: Quantity+=1})
          // }
          // else {
            const doc = await firestore.collection('Leagues').doc(Jersey.League).collection('Teams').doc(Jersey.Team).collection('Cuts').doc(Jersey.Cut).collection('Sleeves').doc(Jersey.Sleeve).collection('Variants').doc(Jersey.Variant).get();
            console.log(doc.data());
            const JerseyImgFront = doc.data().JerseyImgFront;
    
            firestore.collection('Users').doc(uid).set({
              UID: uid
            }).then(
              firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.League+Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size).set({            
                League: Jersey.League,
                Team: Jersey.Team,
                Year: Jersey.Year,
                Cut: Jersey.Cut,
                Sleeve: Jersey.Sleeve,
                Variant: Jersey.Variant,
                Size: Jersey.Size,
                Name: '',
                Number: '',
                Quantity: 1,
                Price: Jersey.Price,  
                JerseyImgFront: JerseyImgFront       
              }) 
            )
        // }
          }
        }

    handleFilterCheckBoxChange(e) {
      var Filters = [];
      
      this.setState({[e.target.name]: e.target.checked}, function () { //update filters in callback function to ensure that we are using the new state
        
      if (this.state.MensFilterChecked == true) {
        Filters.push('Mens') }
      if (this.state.WomensFilterChecked == true) {
        Filters.push('Womens') }
      if (this.state.YouthFilterChecked == true) {
        Filters.push('Youth') }
      if (this.state.OldFilterChecked == true) {
        Filters.push('18-19','19-20','20-21','21-22','22-23','23-24') 
      }
      else{
        Filters.push('23-24')
      }
      if (this.state.SFilterChecked == true) {
        Filters.push('S', 'XXS (16)', 'XS (18)', 'S (20)') }
      if (this.state.MFilterChecked == true) {
        Filters.push('M', 'M (22)') }
      if (this.state.LFilterChecked == true) {
        Filters.push('L', 'L (24)') }
      if (this.state.XLFilterChecked == true) {
        Filters.push('XL', 'XL (26)') }
      if (this.state.XXLFilterChecked == true) {
        Filters.push('2XL', '2XL (28)') } 
      
      
      this.setState({Filters: Filters})
      // console.log('Filters:', this.state.Filters)
      })
    }

  handleTeamSearchChange(e){ //this.setState({financialGoal: event.target.value.replace(/\D/,'')})   
      // const s =   e.target.value
     // PlayerNumber = n 
     this.setState({FilterTeam: [e.target.value]})
    //  props.onPlayerNoteChange(s)
     // setPlayerNumber(e.target.value.replace(/\+|-/ig, ''))
     // console.log(e.target.value)
     
   }


render(){
  ((this.state.Filters == this.InitialFilters) & (this.state.FilterTeam == [''])) ?
      window.history.replaceState(null, 'Soccer Expressionz', `/Search`)
    :
      window.history.replaceState(null, 'Soccer Expressionz', `/Search?Mens=${this.state.MensFilterChecked}&Womens=${this.state.WomensFilterChecked}&Youth=${this.state.YouthFilterChecked}&S=${this.state.SFilterChecked}&M=${this.state.MFilterChecked}&L=${this.state.LFilterChecked}&XL=${this.state.XLFilterChecked}&2XL=${this.state.XXLFilterChecked}&Team=${this.state.FilterTeam}`)
  
  const closeDialog = () => {
    this.setState({AddtoCartDialogShow: false});
  };

  const openDialog = () => {
    this.setState({AddtoCartDialogShow: true});
  }; 

  const AddtoCartDialog = this.state.AddtoCartDialogShow
      &&
      <Dialog onClose = {closeDialog} open = {openDialog}>      
      <br/>
      <center>
        A new item was just added to your cart. {" "}
      </center>
        <br/>
        <div>
        <center>
            <button className="w3-button w3-blue" onClick = {() => (
              this.setState({AddtoCartDialogShow: false}),
              this.props.history.push('/Cart')
              )}>
              View Cart and Checkout
            </button>
            
            <button className="w3-button w3-yellow" onClick = {closeDialog}>
              Continue Shopping
            </button>
        </center>
        </div>
        <br/>
      </Dialog>
  
    return (
        <> 
          <br/>
          <label>Mens</label>
          <input 
              type="checkbox"
              name='MensFilterChecked'
              checked={ this.state.MensFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; Womens</label>
          <input 
              type="checkbox"
              name='WomensFilterChecked'
              checked={ this.state.WomensFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; Youth</label>
          <input 
              type="checkbox"
              name='YouthFilterChecked'
              checked={ this.state.YouthFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; Include Old Stock</label>
          <input 
              type="checkbox"
              name='OldFilterChecked'
              checked={ this.state.OldFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />

            <br/>

            <label>S</label>
          <input 
              type="checkbox"
              name='SFilterChecked'
              checked={ this.state.SFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; M</label>
          <input 
              type="checkbox"
              name='MFilterChecked'
              checked={ this.state.MFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; L</label>
          <input 
              type="checkbox"
              name='LFilterChecked'
              checked={ this.state.LFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; XL</label>
          <input 
              type="checkbox"
              name='XLFilterChecked'
              checked={ this.state.XLFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <label>&nbsp; 2XL</label>
          <input 
              type="checkbox"
              name='XXLFilterChecked'
              checked={ this.state.XXLFilterChecked } 
              onChange={(e) => (this.handleFilterCheckBoxChange(e)) } />
          <br/>
          <br/>
          <label>Team:</label>&nbsp;
          <DebounceInput
            value = {this.state.FilterTeam}
            id= 'SearchTeam'
            className="TextInput"
            placeholder="Search Team..."
            minLength={1}
            maxLength = {55}
            debounceTimeout={500}
            onChange={(e) => (this.setState({FilterTeam: [e.target.value]}))}
            style={{ width: "200px" }}
          />

          <table width='100%'>            
              {this.state.Stock.map((Jersey) => ( 
                this.state.FilterTeam.some (j => (Jersey.Team.toLowerCase().includes(j.toLowerCase()))) && (             
                  this.state.Filters.some (j => (j === Jersey.Cut)) && (
                    this.state.Filters.some (j => (j === Jersey.Size)) && (
                      this.state.Filters.some (j => (j == Jersey.Year)) && (
                        <tr>
                            <td>
                            <StockItem Item={Jersey} />  {/*onQuantityChange={this.handleQuantityChange} */}
                            </td>
                            <td>
                                <BrowserRouter>
                                    <Switch>
                                        <Link to='/cart' onClick={async () => this.handleAddToCart(Jersey)}><button><FaCartPlus/><br/>Add to Cart</button></Link> 
                                    </Switch>  
                                </BrowserRouter>                                    
                            </td>
                        </tr>
                      )
                    )
                  )      
                )
                ))  } 
          </table>
          {AddtoCartDialog}
        </>
         )
        }

}

export default withRouter(SearchStock);