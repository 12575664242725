import React from 'react'
// import {useEffect, useState } from 'react'

import {firestore} from '../config/Config.js'


export class SelectVariant extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        // this.state = {Options: [{label: 'Loading Variants', value: 'Loading Variants'}]}
        // this.GetVariants = this.GetVariants.bind(this);
    }

    handleChange(e) {
        this.props.onVariantSelect(e.target.value)
    }

    

    async componentDidUpdate(prevProps) {
        if ((prevProps.League !== this.props.League) || (prevProps.Team !== this.props.Team) || (prevProps.Cut !== this.props.Cut) || (prevProps.Sleeve !== this.props.Sleeve)) {
            await this.props.GetVariants(); 
        }
    }

    async componentDidMount() {
        this.props.GetVariants();    
    }

    render(){  
        // console.log(this.props)  
        var VariantOptions = [...this.props.VariantOptions];    //copy the array so that we are not using a reference. Otherwise reverse() will not work   
        return (
            <div>
                <label>Select Variant</label>  
                <select onChange={this.handleChange} value={this.props.value}>            
                    {VariantOptions.reverse().map((option) => (
                        // console.log(option),
                        <option value={option.value}>{option.label}</option>
                    ))
                    // console.log('Selectable options...'),
                    // console.table(this.state.Options)
                    }
                </select>
                    
            </div>
        )
    }
}
