import React, {useState} from 'react'
import {storage, firestore} from '../config/Config.js'
// import { setUserProperties } from '@firebase/analytics';

//components
import { SelectLeague } from './SelectLeague';

export class UpdateSalePrice extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            League: 'Your Custom Jersey',            
            Error: '',
            LeagueOptions: [{label: 'Loading Leagues', value: 'Loading Leagues'}],            
            SalePrice: 150
        }
        this.handleLeagueSelect = this.handleLeagueSelect.bind(this);
        this.GetLeagues = this.GetLeagues.bind(this);
        this.handleUpdatePrice = this.handleUpdatePrice.bind(this);
        
    }

    async GetLeagues() {
        const data = [];
        const Leagues = await firestore.collection('Leagues').get()
        for (var doc of Leagues.docs){
            data.push({value: doc.data().League, label: doc.data().League}); 
        }
        // console.log(data)
        this.setState({LeagueOptions: data});
      }

    

    handleLeagueSelect(league) {  
    //THIS CODE IS REPEATED ELSEWHERE
    //we need to query the first team in the new league collection in order to change the Team select box
    //otherwise the team from the old league will still be set and the lookup for the rest of the select boxes will fail.
    firestore.collection("Leagues").doc(league).collection("Teams")
    .orderBy("Team", "desc")
    .limit(1)
    .get().then(querySnapshot => {
    if (!querySnapshot.empty) {
        //We know there is one doc in the querySnapshot                  
            const queryDocumentSnapshot = querySnapshot.docs[0];                  
            this.setState({Team: queryDocumentSnapshot.data().Team})
    }
    });        

    // let StateCopy = this.state;
    // StateCopy.League = league;
    // this.setState({StateCopy});
    this.setState({League: league});
    }



    handleUpdatePrice(e) {

    e.preventDefault()
    console.log('changing');
    //iterate through every Team, Cut and Sleeve and set the sale price
    firestore.collection('Leagues').doc(this.state.League).collection('Teams').get().then(queryTeamSnapshot => {
        if (queryTeamSnapshot.empty) {
            console.log('No Teams returned')
        }
        else
        {   queryTeamSnapshot.forEach((Teamdoc) => {
                // console.log(Teamdoc.data().Team);
                firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(Teamdoc.id).collection('Cuts').get().then(queryCutSnapshot => {
                    if (queryCutSnapshot.empty) {
                        console.log('No Cuts returned')
                    }
                    else
                    {   queryCutSnapshot.forEach((Cutdoc) => {
                            // console.log(Cutdoc.data().Cut);
                            firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(Teamdoc.id).collection('Cuts').doc(Cutdoc.id).collection('Sleeves').get().then(querySleeveSnapshot => {
                                if (querySleeveSnapshot.empty) {
                                    console.log('No Sleeves returned')
                                }
                                else
                                {   querySleeveSnapshot.forEach((Sleevedoc) => {
                                        // console.log(Sleevedoc.data().Sleeve);
                                        firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(Teamdoc.id).collection('Cuts').doc(Cutdoc.id).collection('Sleeves').doc(Sleevedoc.id).set({Sleeve: Sleevedoc.id, SalePrice:this.state.SalePrice});                                        
                                        console.log(Teamdoc.data().Team, Cutdoc.data().Cut, Sleevedoc.id);
                                    })
                                }
                            })    
                        })                        
                    }
                })
            })
        }
    })

    }

    


    render() {
        return (        
            <div className='container'>
                <h2>Select Year to mass update</h2>
                <form onSubmit={this.handleUpdatePrice}> {/**/}            
                    <SelectLeague onLeagueSelect={this.handleLeagueSelect} LeagueOptions={this.state.LeagueOptions} GetLeagues={this.GetLeagues}/>            
                    
                    <label>Sale Price</label>                    
                     <input type="number"  
                     value = {this.state.SalePrice}                  
                    onChange={(e) => this.setState({SalePrice: e.target.valueAsNumber})}/>
                    <br/>


                    <button>Change</button>
                </form>
                {Error && <span>{Error}</span>}
            </div>
        )
    }
}
