import React from 'react'
// import {useEffect, useState } from 'react'

import {firestore} from '../config/Config.js'


export class SelectTeam extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        // this.state = {Options: [{label: 'Loading Teams', value: 'Loading Teams'}]}
        // this.GetTeams = this.GetTeams.bind(this);
    }

    handleChange(e) {
        this.props.onTeamSelect(e.target.value)
    }

    

    async componentDidUpdate(prevProps) {
        
        if (prevProps.League !== this.props.League) {
            this.props.GetTeams(); 
            // console.log(prevProps.League, this.props.League)
        }
    }

    async componentDidMount() {
        // console.log('MOUNT');
        this.props.GetTeams();    
    }

    render(){  
        // console.log(this.props.value)
        var TeamOptions = [...this.props.TeamOptions]; //copy the array so that we are not using a reference. Otherwise reverse() will not work 
        return (
            <div>
                <label>Select Team</label>  
                <select onChange={this.handleChange} value={this.props.value}>            
                    {TeamOptions.reverse().map((option) => (                        
                        <option value={option.value}>{option.label}</option>
                    ))
                    // console.log('Selectable options...'),
                    // console.table(this.state.Options)
                    }
                </select>
                    
            </div>
        )
    }
}
