import React from 'react'
import {firestore} from '../config/Config.js'
// import {RiDeleteBin5Line} from 'react-icons/ri'
// import {BrowserRouter, Switch, Route, Link} from 'react-router-dom'
import { DebounceInput } from 'react-debounce-input'

export class CartItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            URL: '',
            quantity: this.props.Item.Quantity,
            PlayerNote: this.props.Item.PlayerNote,
            max: 2,
            min: 0,}
        // this.props.Item = this.props.Item.data();
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handlePlayerNoteChange = this.handlePlayerNoteChange.bind(this);
        
    }

    async componentDidMount() {
        // console.log(this.props.Item)
        var max = 1
        firestore.collection('Leagues').doc(this.props.Item.League).collection('Teams').doc(this.props.Item.Team).collection('Cuts').doc(this.props.Item.Cut).collection('Sleeves').doc(this.props.Item.Sleeve).collection('Variants').doc(this.props.Item.Variant).collection('Sizes').doc(this.props.Item.Size).get()
        .then((doc) => {if (doc.data() != undefined) {
                            max = doc.data().StockQuantity;
                            if (max == 0){
                                max = 1
                            };
                        }
                        this.setState({max: max});
                }
            )
    }

    handleQuantityChange(e) {
        value = e.target.value
        if (e.target.value>this.state.max) {
            var value = this.state.max;
        }
        if (e.target.value<this.state.min) {
            var value = this.state.min;
        }
        this.setState({quantity: value});
        this.props.onQuantityChange(value, this.props.Item); //e.target.value, this.this.props.Item
    }  

    handlePlayerNoteChange(e) {
        this.setState({PlayerNote: e.target.value});
        this.props.onPlayerNoteChange(e.target.value, this.props.Item); //e.target.value, this.this.props.Item 
    } 
    
    
    render() {
        return (  
            <div class='CartItem'>
                <table class='CartItem'>
                    <tr>
                        <td>
                            <img class='JerseyImgThumb' src={this.props.Item.JerseyImgFront}/>
                            <br/>
                            <label>{this.props.Item.Year} {this.props.Item.Team} {this.props.Item.Cut} <br/> {this.props.Item.Sleeve} Sleeve<br/> {this.props.Item.Variant}</label>
                        </td>
                        <td>
                            <label>Size: {this.props.Item.Size}</label> {/* &nbsp; Size Chart */}
                            <br/>
                            <label>Player: {this.props.Item.PlayerName} #{this.props.Item.PlayerNumber}</label>
                            <br/>
                            <label>Note: {this.state.PlayerNote}</label> 
                            {/* <DebounceInput
                                className="TextInput"
                                placeholder="Leave requests here..."
                                minLength={1}
                                debounceTimeout={500}
                                onChange={this.handlePlayerNoteChange}
                                value={this.state.PlayerNote}
                                /> */}
                            <br/>
                            <label>Quantity: </label> 
                            <input style={{ width:"3vw" }} type='number' onChange={this.handleQuantityChange} value={this.state.quantity}/>
                        </td>
                        <td>
                            <label>TT$ {this.props.Item.Price*this.state.quantity}</label>
                        </td>
                    </tr>                    
                </table>
                <br/>
            </div>
        )
    }
}


