import React from 'react'
// import {useEffect, useState } from 'react'

import {firestore} from '../config/Config.js'


export class SelectPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        //we need an initial option for mediums, as the app will crash if it cannot find a medium in the options before the Players are loaded from firestore
        this.state = {selectValue:'Custom', Options: [{label: 'Loading Players', value: 'Loading Players'}, {label: 'Custom', value: 'Custom'}, {label: 'None', value: 'None'}]} 
        this.GetPlayers = this.GetPlayers.bind(this);
    }

    handleChange(e) {
        var Name = 'ANY NAME'
        var Num = '00'
        if (e.target.value !== 'Custom') 
            {Name = e.target.value.split(' #')[0];
            Num = e.target.value.split(' #')[1]}
        if (e.target.value === 'None') 
            {Name = '';
            Num = ''}

        this.setState({selectValue: e.target.value});
        this.props.onPlayerSelect(Name,Num);
        

    }

    async GetPlayers() {
        const data = [ {label: 'Custom', value: 'Custom'}, {label: 'None', value: 'None'}];
        const Players = await firestore.collection('Leagues').doc(this.props.League).collection('Teams').doc(this.props.Team).collection('Players').get()
        for (var doc of Players.docs){
            data.push({value: doc.data().Player, label: doc.data().Player});    
            // Options.push(doc.data())
        }
        
        this.setState({Options: data});
        // this.setState({selectValue: data.at(-1).value});
        // this.setState({selectValue: data.at(-1).value}, {Options: data});
        
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.GetPlayers();
        }
    }

    async componentDidMount() {
        this.GetPlayers();
    }

    render(){
        
        return (
            <>
            <div>
                <label>Select Player</label>  
                <select value={this.state.selectValue} onChange={this.handleChange}> {/* value={this.state.Options[0].value}*/} 
                    {
                    this.state.Options.reverse().map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))
                    }
                </select>
                    
            </div>
            </>
        )
    }
}

