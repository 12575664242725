import React from 'react'
import {firestore} from '../config/Config.js'
// import {RiDeleteBin5Line} from 'react-icons/ri'
// import {BrowserRouter, Switch, Route, Link} from 'react-router-dom'
import { DebounceInput } from 'react-debounce-input'

export class DeliveryItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordernumber: this.props.Delivery.ordernumber,
            DeliveryName: this.props.Delivery.DeliveryName,
            DeliveryTelNumber: this.props.Delivery.DeliveryTelNumber,
            trackingURL: '',
            Email:this.props.Delivery.Email}
        this.handleAddTracking = this.handleAddTracking.bind(this);
        
    }

    async handleAddTracking() {

        console.log('Sending mail...'); 
        firestore.collection('mail').add({
            to: this.state.Email,
            cc: 'soccerexpressionztt@gmail.com',
            template: {
                name: 'tracking',
                data: {
                    ordernumber: this.state.ordernumber,
                    DeliveryName: this.state.DeliveryName,
                    DeliveryTelNumber: this.state.DeliveryTelNumber,
                    trackingURL: this.state.trackingURL,
                    Email: this.state.Email
                    }
                }
            }).then(() => console.log('Queued email for delivery!'), alert('Tracking Email Sent')).then(() => console.log('complete'), this.props.onAddTracker())
                          
    }
    
    
    render() {
        return (  
            <div class='CartItem'>
                <table class='CartItem'>
                    <tr>
                        <td>
                            <label>Order Number: &nbsp;</label>
                            <label>{this.state.ordernumber} &nbsp;</label>
                        </td>
                        <td>
                            <label>Name: &nbsp;</label>
                            <label>{this.state.DeliveryName} &nbsp;</label>
                        </td>
                        <td>
                            <label>Contact: &nbsp;</label>
                            <label>{this.state.DeliveryTelNumber} &nbsp;</label>
                        </td>
                        <td>
                            <label>Email: </label> 
                            <input type='text' onChange={(e) => {this.setState({Email: e.target.value})}} value={this.state.Email}/>
                        </td>
                        <td>
                            <label>Tracking URL: </label>
                            <input type='text' onChange={(e) => {this.setState({trackingURL: e.target.value})}} value={this.state.trackingURL}/>
                        </td>
                        <td>
                            <button onClick={() => this.handleAddTracking()}>ADD</button>
                        </td>
                    </tr>                    
                </table>
                <br/>
            </div>
        )
    }
}


