import React, {useState} from 'react'
import {storage, firestore} from '../config/Config.js'
// import { setUserProperties } from '@firebase/analytics';

//components
import { SelectLeague } from './SelectLeague';
import { SelectTeam } from './SelectTeam';

export class AddPlayers extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            League: 'Your Custom Jersey',
            Team: 'Generic',            
            Error: '',
            LeagueOptions: [{label: 'Loading Leagues', value: 'Loading Leagues'}],
            TeamOptions: [{label: 'Loading Teams', value: 'Loading Teams'}],
            TextAreaValue: '',
        }
        this.handleLeagueSelect = this.handleLeagueSelect.bind(this);
        this.handleTeamSelect = this.handleTeamSelect.bind(this);
        this.handleAddPlayers= this.handleAddPlayers.bind(this);
        this.GetLeagues = this.GetLeagues.bind(this);    
        this.GetTeams = this.GetTeams.bind(this);        
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this);  
    }

    async GetLeagues() {
        const data = [];
        const Leagues = await firestore.collection('Leagues').get()
        for (var doc of Leagues.docs){
            data.push({value: doc.data().League, label: doc.data().League}); 
        }
        // console.log(data)
        this.setState({LeagueOptions: data});
      }

    async GetTeams(){
    if (this.state.League !==''){ //test for undefined props which may come from AddJersey page
        const data = [];
        const Teams = await firestore.collection('Leagues').doc(this.state.League).collection('Teams').get()
        for (var doc of Teams.docs){
            data.push({value: doc.data().Team, label: doc.data().Team, Year: doc.data().Year})    
        }
        
        //immediately set Team based on TeamOptions
        if (data.length > 0) {
        this.setState({TeamOptions: data, Team: data[data.length-1].value, Year: data[data.length-1].Year});
        }
    }
    }

    

      handleLeagueSelect(league) {  
        //THIS CODE IS REPEATED ELSEWHERE
        //we need to query the first team in the new league collection in order to change the Team select box
        //otherwise the team from the old league will still be set and the lookup for the rest of the select boxes will fail.
        firestore.collection("Leagues").doc(league).collection("Teams")
        .orderBy("Team", "desc")
        .limit(1)
        .get().then(querySnapshot => {
        if (!querySnapshot.empty) {
            //We know there is one doc in the querySnapshot                  
                const queryDocumentSnapshot = querySnapshot.docs[0];                  
                this.setState({Team: queryDocumentSnapshot.data().Team})
        }
        });        

        // let StateCopy = this.state;
        // StateCopy.League = league;
        // this.setState({StateCopy});
        this.setState({League: league});
      }
    
      handleTeamSelect(team) {  
        // let StateCopy = this.state;
        // StateCopy.Team = team;
        // this.setState({StateCopy});
        this.setState({Team: team});
      }
    
      
      async handleAddPlayers(e) {
        e.preventDefault();

        //add new player
        var count = 0
        var Players = this.state.TextAreaValue.split(/\n/);
        console.log(Players)
        for (let step = 0; step < Players.length; step++) {
        // for (var player in Players) {
            // console.log(Players[step])
            if (Players[step] != ''){
                await firestore.collection('Leagues').doc(this.state.League).collection('Teams').doc(this.state.Team).collection('Players').doc(Players[step]).set({Player: Players[step]})
            }
            count = step;
        }
        alert('Players added: '+count)
        this.setState({League: 'Your Custom Jersey',Team: 'Generic',TextAreaValue: ''})
        
                
    }

    handleTextAreaChange(e) {
        this.setState({TextAreaValue: e.target.value.toUpperCase()});
      }

    


    render() {
        return (        
            <div className='container'>
                <h2>Add Players</h2>
                <form onSubmit={this.handleAddPlayers}> {/**/}            
                <SelectLeague onLeagueSelect={this.handleLeagueSelect} LeagueOptions={this.state.LeagueOptions} GetLeagues={this.GetLeagues}/>
                <SelectTeam onTeamSelect={this.handleTeamSelect} League={this.state.League} TeamOptions={this.state.TeamOptions} GetTeams={this.GetTeams}/>                   
                    
                    <textarea
                        value={this.state.TextAreaValue}
                        onChange={this.handleTextAreaChange}
                        rows={22}
                        cols={30}
                        />

                    <br/><br/>

                    <button>ADD</button>
                </form>
                {Error && <span>{Error}</span>}
            </div>
        )
    }
}
