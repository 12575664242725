import React from 'react'
import {firestore} from '../config/Config.js'
import {Link} from 'react-router-dom'
import {TbShirtSport} from 'react-icons/tb'
// import {BrowserRouter, Switch, Route, Link} from 'react-router-dom'

export class StockItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {URL: '', quantity: this.props.Item.Quantity}
        // this.props.Item = this.props.Item.data();
        // this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const doc = await firestore.collection('Leagues').doc(this.props.Item.League).collection('Teams').doc(this.props.Item.Team).collection('Cuts').doc(this.props.Item.Cut).collection('Sleeves').doc(this.props.Item.Sleeve).collection('Variants').doc(this.props.Item.Variant).get();
        this.setState({URL: doc.data().JerseyImgFront});
        // return doc.data().JerseyImg;
    }

    handleChange(e) {
        this.setState({quantity: e.target.value});
        this.props.onQuantityChange(e.target.value, this.props.Item); //e.target.value, this.this.props.Item
    }   
    
    
    render() {
        
        return (  
            <div class='CartItem'>
                <table class='CartItem'>
                    <tr>

                        <td> <Link to={
                                    //CONSIDER USING URLSearchParams TO PROPERLY ESCAPE CHARACTERS LIKE SPACES, HYPENS, ETC
                                    `/?Year=${this.props.Item.League}&Team=${this.props.Item.Team}&Cut=${this.props.Item.Cut}&Sleeve=${this.props.Item.Sleeve}&Variant=${this.props.Item.Variant}&Size=${this.props.Item.Size}`                             
                                    // state: this.props.Item
                                }>
                                <button><img class='JerseyImgThumb' src={this.state.URL} onClick={this.myfunction} /><br/><TbShirtSport/><br/>Customize</button>
                                </Link>
                        </td>

                        {
                            
                        // <td>{this.props.Item.JerseyImgFront}</td>
                        // <td><img class='JerseyImgThumb' src={this.state.URL}/> </td>

                        
                            
                        //     <td>
                        //     <Link to={
                        //             //CONSIDER USING URLSearchParams TO PROPERLY ESCAPE CHARACTERS LIKE SPACES, HYPENS, ETC
                        //             `/?Year=${this.props.Item.League}&Team=${this.props.Item.Team}&Cut=${this.props.Item.Cut}&Sleeve=${this.props.Item.Sleeve}&Variant=${this.props.Item.Variant}&Size=${this.props.Item.Size}`                             
                        //             // state: this.props.Item
                        //         }
                        //     >
                        //     {/* <Link to={{
                        //             pathname: `/?Year=${this.props.Item.Year}&Team=${this.props.Item.Team}&Cut=${this.props.Item.Cut}&Sleeve=${this.props.Item.Sleeve}&Variant=${this.props.Item.Variant}`,                                    
                        //             state: this.props.Item
                        //         }}> */}
                        //     <button><TbShirtSport/><br/>Customize</button></Link>
                        // </td>
                        
                        }
                        
                        <td><label>{this.props.Item.Year} {this.props.Item.Team} {this.props.Item.Cut} <br/> {this.props.Item.Sleeve} Sleeve<br/> {this.props.Item.Variant}</label> </td>

                        <td><label>Size: <b>{this.props.Item.Size}</b></label> 
                        {/* <br/> <label>Player: {this.props.Item.Name} #{this.props.Item.Number}</label>  */}
                        {/* <td>
                            <label>Quantity: </label> 
                            <input class="SmallTextInput" type='text' onChange={this.handleChange} value={this.state.quantity}/>
                        </td> */}
                        <br/>
                        <label>Left: {this.props.Item.quantity}</label>
                        <br/>
                        <label>TT${this.props.Item.Price}</label>    
                        </td>
                        
                    </tr>
                </table>
                <br/>
            </div>
        )
    }
}
