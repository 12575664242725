import React from 'react'
// import {useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import {firestore} from '../config/Config.js'

export class JerseyImage extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);        
        this.state = {images: []}
        this.GetJerseyImgs = this.GetJerseyImgs.bind(this);
    }

    handleChange(e) {
        this.props.onVariantSelect(e.target.value)
    }

    async GetJerseyImgs(){
        const imgs = [];
        const Variants = await firestore.collection('Leagues').doc(this.props.League).collection('Teams').doc(this.props.Team).collection('Cuts').doc(this.props.Cut).collection('Sleeves').doc(this.props.Sleeve).collection('Variants').get()
        console.log(this.props.Sleeve)
        for (var doc of Variants.docs){
            if (doc.data().Variant === this.props.Variant) {                    
                imgs.push(doc.data().JerseyImgBack)
                imgs.push(doc.data().JerseyImgFront)
                console.log(imgs)
                break; }
        }
        this.setState({images: imgs})
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.GetJerseyImgs();
        }
    }

    async componentDidMount() {
        this.GetJerseyImgs();
    }

    render(){
        // console.log(this.props)
        
        return (
            <>
                <div className="Jerseycontainer">  
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <img class="JerseyImg" src={this.state.images[0]} alt={this.props.Team+this.props.Cut+this.props.Sleeve+this.props.Variant}/>
                                {/* <div className="centered"> 
                                    <p className="playername">{this.props.PlayerName}</p>
                                    <p className="playernumber">{this.props.PlayerNumber}</p>
                                </div> */}

                                <div className="centered"> 
                                    {/* <div className="centered">  */}
                                        <svg viewBox="0 40 500 500">
                                            <path fill-opacity="0.0" id="curve" d="M 123.2 148.6 c 4 -6.1 65.5 -96.8 128.6 -95.6 c 62.2 1 120.8 90.3 125.1 97"/>
                                            {/* " M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" */}
                                            {/* "M 73.2 198.6 c 4 -6.1 40.5 -146.8 178.6 -145.6 c 111.3 1.2 170.8 90.3 174.2 147" */}
                                            {/* {console.log(this.props.styleObj)} */}
                                                <text className="playername" text-anchor="middle" width="500" style={this.props.styleObj}>
                                                    <textPath xlinkHref="#curve" startOffset="50%">                                                                                
                                                    {this.props.PlayerName}
                                                    </textPath>
                                                </text>
                                        </svg>   
                                    {/* </div>            */}
                                    {/* <p className="playername">{props.PlayerName}</p> */}
                                    <div className={this.props.NumberCSS}> 
                                        
                                        <text className="playernumber"  style={this.props.styleObj}>{this.props.PlayerNumber}</text>
                                    </div>
                                </div>

                                </SwiperSlide>
                            <SwiperSlide><img class="JerseyImg" src={this.state.images[1]} alt={this.props.Team+this.props.Cut+this.props.Sleeve+this.props.Variant}/></SwiperSlide>                                                    
                        </Swiper>

                    {/* <img class="JerseyImg" src={this.state.JerseyImgSrc} alt={this.props.Team+this.props.Cut+this.props.Sleeve+this.props.Variant}/>                    */}
                
                    <div className="centered"> 
                    {/* <svg viewBox="0 0 500 500">
                        <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
                        <text width="500">
                        <textPath xlinkHref="#curve">
                        {this.state.PlayerName}
                        </textPath>
                        </text>
                    </svg>              */}
                    {/* <p className="playername">{this.state.PlayerName}</p>
                    <p className="playernumber">{this.state.PlayerNumber}</p> */}
                    </div>
            </div>
          </>


            
            )
    }
}