import React from 'react'
import { useState } from 'react'
import {firestore, Timestamp} from '../config/Config.js'
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import { withRouter } from 'react-router';
import {StockItem} from './StockItem.js'

//icons
import { MdRemoveShoppingCart, MdTungsten } from "react-icons/md"
import { RiEmotionSadLine } from "react-icons/ri"
import {RiDeleteBin5Line} from 'react-icons/ri'
import { FaCartPlus } from "react-icons/fa"

export class CustomOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          League: 'EPL',
          Team: 'Chelsea',
          Year: '21-22',
          Cut: 'Mens',
          Sleeve: 'Short',
          Variant: 'Home',
          Size: 'M',
          Name: '',
          Number: '',
          Quantity: 1,
          Price: 350,
        }
        
    this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    

    

    async handleAddToCart() {

      if (this.props.user == null) {
        await this.props.AnonSignIn();  
        // console.log(this.props.user)
      }
      this.AddToCart(this.props.user.uid);
      alert('Added to Cart')
      setTimeout(() => {  this.props.history.push('/BankTransferCart'); }, 1000);
      
    }      

    async AddToCart(uid) {
        // console.log(uid);
        if (uid !== null) {
          
          // console.log(Jersey);
          // if (firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).exists) {
          //   firestore.collection('Users').doc(uid).collection('Cart').doc(Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+Jersey.PlayerName+Jersey.PlayerNumber).update({Quantity: Quantity+=1})
          // }
          // else {
            // const doc = await firestore.collection('Leagues').doc(Jersey.League).collection('Teams').doc(Jersey.Team).collection('Cuts').doc(Jersey.Cut).collection('Sleeves').doc(Jersey.Sleeve).collection('Variants').doc(Jersey.Variant).get();
            // console.log(doc.data());
            // const JerseyImgFront = doc.data().JerseyImgFront;
            var name = this.state.Name
            var number = this.state.Number
            if (name == undefined) {
              name = ''
            }
            if (number == undefined) {
              number = ''
            }
            
    
            firestore.collection('Users').doc(uid).set({
              UID: uid
            }).then(
              firestore.collection('Users').doc(uid).collection('Cart').doc(this.state.League+this.state.Team+this.state.Cut+this.state.Sleeve+this.state.Variant+this.state.Size+name+number).set({            
                League: this.state.League,
                Team: this.state.Team,
                Year: this.state.Year,
                Cut: this.state.Cut,
                Sleeve: this.state.Sleeve,
                Variant: this.state.Variant,
                Size: this.state.Size,
                PlayerName: name,
                PlayerNumber: number,
                Quantity: 1,
                Price: Number(this.state.Price),  
                JerseyImgFront: 'https://firebasestorage.googleapis.com/v0/b/soccerexpressionz-test.appspot.com/o/jersey-images%2FGeneric_Mens_Front.png?alt=media&token=90dea5cb-ed57-40b2-9c54-3db98862f733',       
              }) 
            )
        // }
          }
        }

    


render(){   
    return (
        <> 
          <br/>
          <label>League</label>
          <input 
              type="text"
              onChange={(e) => this.setState({League: e.target.value})}
              value={this.state.League} />
          <br/>
          <label>Team</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Team: e.target.value})}
              value={this.state.Team} />
          <br/>
          <label>Year</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Year: e.target.value})}
              value={this.state.Year} />
          <br/>
          <label>Cut</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Cut: e.target.value})}
              value={this.state.Cut} />
          <br/>
          <label>Sleeve</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Sleeve: e.target.value})}
              value={this.state.Sleeve} />
          <br/>
          <label>Variant</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Variant: e.target.value})}
              value={this.state.Variant} />
          <br/>
          <label>Size</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Size: e.target.value})}
              value={this.state.Size} />
          <br/>
          <label>Name</label>
          <input 
              type="text"
              onChange={(e) => this.setState({Name: e.target.value})}
              value={this.state.Name} />
          <br/>
          <label>Number</label>
          <input 
              type="number"
              onChange={(e) => this.setState({Number: e.target.value})}
              value={this.state.Number} />
          <br/>
          <br/>
          <label>Price</label>
          <input 
              type="number"
              onChange={(e) => this.setState({Price: e.target.value})}
              value={this.state.Price} />
          <br/>
          

          <button onClick={this.handleAddToCart}>ADD TO CART TT${this.state.Price}</button>
        </>
         )
        }

}

