import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth'
import 'firebase/compat/storage'

// import { useAuthState } from 'react-firebase-hooks/auth'
// import { useCollectionData } from 'react-firebase-hooks/firestore'

// import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";



const firebaseConfig = {
  apiKey: "AIzaSyAoLPgkbTcasp-gN19mu8O3H-EjxE-kzNw",
  // authDomain: "soccerexpressionz-test.firebaseapp.com",
  authDomain: "soccerexpressionz.com",
  projectId: "soccerexpressionz-test",
  storageBucket: "soccerexpressionz-test.appspot.com",
  messagingSenderId: "950374214946",
  appId: "1:950374214946:web:78a3c35563b9927cabc97b",
  measurementId: "G-M7X8NJNW0L"
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = firebase.auth();
const auth2 = firebase.auth;
const firestore = firebase.firestore();
const storage = firebase.storage();
const Timestamp = firebase.firestore.Timestamp;

const GlobalJerseyPrice = {
  Custom: 150,
  Youth: 300,
  Womens: 350,
  MensShort: 350,
  MensLong: 400,
}

export {firebase, auth, auth2, firestore, storage, app, analytics, Timestamp, GlobalJerseyPrice}