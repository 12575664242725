import React from 'react'
import {firestore} from '../config/Config.js'
import {DeliveryItem} from './DeliveryItem.js'
import {BrowserRouter, Switch, Link} from 'react-router-dom'
import {RiDeleteBin5Line} from 'react-icons/ri'

export class TrackingEmail extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            Deliveries: [],
        }
        this.handleAddTracking = this.handleAddTracking.bind(this);
        this.handleRemoveFromTracking = this.handleRemoveFromTracking.bind(this);
        this.GetDeliveries = this.GetDeliveries.bind(this);
        // this.RetroAddTracking = this.RetroAddTracking(this);
    }

    async handleAddTracking(e) {
        e.preventDefault();

        console.log('Sending mail...'); 
        firestore.collection('mail').add({
            to: this.state.Email,
            cc: 'soccerexpressionztt@gmail.com',
            template: {
                name: 'tracking',
                data: {
                    ordernumber: this.state.ordernumber,
                    DeliveryName: this.state.DeliveryName,
                    DeliveryTelNumber: this.state.DeliveryTelNumber,
                    trackingURL: this.state.URL,
                    Email: this.state.Email
                    }
                }
            }).then(() => console.log('Queued email for delivery!'), alert('Tracking Email Sent'));                
    }

    async GetDeliveries() {
        this.setState({Deliveries: []});
        const Deliveries = await firestore.collection('tracking').get();
        for (var doc of Deliveries.docs){
            // console.log(doc.data())
            this.setState({Deliveries: [...this.state.Deliveries, doc.data()]}); //ARRAY DOUBLES HERE?!?
        }
    }

    async componentDidMount() {
        await this.GetDeliveries(); 
    }

    async handleRemoveFromTracking(Delivery){  
        await firestore.collection('tracking').doc(Delivery.ordernumber).delete().then(async () => {
            await this.GetDeliveries();
        })       
    }

    // async RetroAddTracking() {
    //     const mail = await firestore.collection('mail').get();
    //     for (var doc of mail.docs){
    //         if (doc.data().template.data.deliver) {
    //             firestore.collection('tracking').doc(doc.data().template.data.ordernumber).set({
    //                 //set any delivery details
    //                 ordernumber: doc.data().template.data.ordernumber,
    //                 DeliveryName: doc.data().template.data.name,
    //                 trackingURL: '',
    //                 Email: doc.data().to
    //             });
    //             console.log(
    //                 doc.data().template.data.ordernumber,
    //                 doc.data().template.data.name,
    //                 doc.data().to
    //                 );
                
    //         }
    //         // this.setState({temp: [...this.state.temp, doc.data()]}); //ARRAY DOUBLES HERE?!?
    //     }

    // }

render() {    
    return (  
        <div className='container'>
            <h2>Add Tracking Confirmation</h2>

            {this.props.user ?
            
            <table className='center'>
                {this.state.Deliveries.map((Delivery) => (
                        
                    <tr>
                        <td>
                            <DeliveryItem Delivery={Delivery} onAddTracker={()=>this.handleRemoveFromTracking(Delivery)}/>
                        </td>
                        <td>                                            
                            <BrowserRouter>
                                <Switch>
                                    <Link to='/tracking' onClick={async () => this.handleRemoveFromTracking(Delivery)}><RiDeleteBin5Line size={32}/></Link> 
                                </Switch>  
                            </BrowserRouter>                         
                        </td>
                    </tr>
                ))  } 
            </table> 

            :

            <>Please Sign In to begin sending tracking info</>

            }

            {/* <button onClick={()=>this.RetroAddTracking()}>SEARCH</button> */}

            </div>
    )
    
    }
}
