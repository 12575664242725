import React from 'react'
// import {useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {firestore} from '../config/Config.js'


export class SelectSize extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {Show: false}
        //we need an initial option for mediums, as the app will crash if it cannot find a medium in the options before the sizes are loaded from firestore        
        // this.state = {selectValue:'M', Options: [{label: 'Loading Sizes', value: 'Loading Sizes', QuantityInStock: ''}]} 
        // this.GetSizes = this.GetSizes.bind(this);
    }

    handleChange(e) {
        this.props.onSizeSelect(e.target.value)
    }


    async componentDidUpdate(prevProps) {
        if ((prevProps.League !== this.props.League) || (prevProps.Team !== this.props.Team) || (prevProps.Cut !== this.props.Cut) || (prevProps.Sleeve !== this.props.Sleeve) || (prevProps.Variant !== this.props.Variant)) {
            this.props.GetSizes();            
        }
    }

    async componentDidMount() {
        this.props.GetSizes();
    }


    render(){ 
        // console.log(this.props.value)       
        var SizeOptions = [...this.props.SizeOptions];  //copy the array so that we are not using a reference. Otherwise reverse() will not work 
        return (
            <>
            <div>
                <label>Select Size</label>  
                <select onChange={this.handleChange} value={this.props.value}>
                    {SizeOptions.reverse().map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))
                    }
                </select>
                &nbsp; <button style={{margin:0+'em', padding: 2+'px'}} onClick={() => {this.setState({Show: true})}}>Size Chart</button>
            </div>
            <div>
                <label>Left in Stock: {this.props.FindQuantityInStock()}</label>
                {this.props.FindQuantityInStock() === 0 && <React.Fragment><br/><label class='notice'>Item is Out of Stock. Please allow 3-4 weeks for shipping with an additional 70TT$ shipping fee.</label></React.Fragment>}     
            </div>

            <Modal scrollable size='xl' show={this.state.Show} onHide={() => {this.setState({Show: false})}}>
                <Modal.Header closeButton>
                <Modal.Title>Sizing Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <img src='https://firebasestorage.googleapis.com/v0/b/soccerexpressionz-test.appspot.com/o/Mens_Sizes.png?alt=media&token=904b8cd0-6a61-41b0-9f0b-a8a274ae2285'></img>
                    <br/>
                    <img src='https://firebasestorage.googleapis.com/v0/b/soccerexpressionz-test.appspot.com/o/Womens_Sizes.png?alt=media&token=5612b164-a726-444f-ba23-8e76b45cec4b'></img>
                    <br/>
                    <img src='https://firebasestorage.googleapis.com/v0/b/soccerexpressionz-test.appspot.com/o/Kids_Sizes.png?alt=media&token=c665ecd9-72a7-42d6-a293-502eed845915'></img>                 */}
                    <img src='https://firebasestorage.googleapis.com/v0/b/soccerexpressionz-test.appspot.com/o/Sizes.png?alt=media&token=847a088a-9042-47a6-9411-b2350e5fdeec'></img>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => {this.setState({Show: false})}}>
                    Close
                </Button>                
                </Modal.Footer>
            </Modal>
            </>
        )
    }
}
