import React, {useState} from 'react'
import {storage, firestore, Timestamp} from '../config/Config.js'
// import { setUserProperties } from '@firebase/analytics';

//components

export class Promos extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            // CustomerName: '',
            ReviewText: '',
            Email: '',
            // Date: 0, 
            ReviewImg: '', 
            // TotalScore: 0,  
            // AverageScore: 0, 
            Reviews: []      
        };
        this.GeneratePromoCode = this.GeneratePromoCode.bind(this);
    }

    
    async GeneratePromoCode(e) {
        e.preventDefault();

        if (this.props.user) {
            if (!this.props.user.isAnonymous || this.props.user.email != 'soccerexpresionztt@gmail.com')
            var Email = this.props.user.Email
        }
        else{
            var Email = this.state.Email
        }

        const query = firestore.collection('PromoCodes')
                        .where('Email', '==', Email)
                        .get()
                        .then(async (querySnapshot) => {
                            if (querySnapshot.empty) {
                                //there is no matching email that has already received a promocode for review. Go ahead and give one.

                                let letters = "0123456789ABCDEF";
                                var code = ''
                                
                                // Generating 3 digit hexcode
                                for (let i = 0; i < 3; i++)
                                    code += letters[(Math.floor(Math.random() * 16))];

                                

                                //create firestore doc
                                await firestore.collection('PromoCodes').doc('ZREV50$'+code).set({   
                                    Code: 'ZREV50$'+code,
                                    Description: "50$ OFF",
                                    Price: 50,
                                    Quantity: 1,
                                    Email: Email,
                                })  

                                console.log('Promocode: '+'ZREV50$'+code)

                                firestore.collection('mail').add({
                                    to: Email,
                                    cc: 'soccerexpressionztt@gmail.com',
                                    template: {
                                        name: 'promocode',
                                        data: {
                                            promocode: 'ZREV50$'+code
                                            }
                                        }
                                    }).then(() => console.log('Queued email for delivery!'), alert('As a thank you for your review a 50$ PromoCode ZREV50$'+code +' has been sent to '+Email+'. Thank you for your feedback!')); 

                            }
                            else {
                                //if a matching email is found then this user has already received a promocode for a review
                                alert('You have already received a promocode.')                          
                          }
                        })
                              

         
    
    }


        

    


    render() {

        var Reviews = [...this.state.Reviews] //copy this array so that we can reverse it later

        return (     
            <>
            <br/>
            <br/>
            <div className='container'>
                
                <form onSubmit={this.GeneratePromoCode}> {/**/}                 
                    

                    {(!this.props.user || (this.props.user && (this.props.user.isAnonymous || this.props.user.email == 'soccerexpressionztt@gmail.com'))) &&
                    <>      
                        <label>Email: &nbsp;</label>                                
                        <input 
                        placeholder='email@domain.com...'
                        required
                        class="" type='text' /* required={this.props.user.isAnonymous} */ onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                    </>             
                    }
                    <br/>
                    <br/>
                    
                    <button>Get Promo Code</button>
                </form>
                {Error && <span>{Error}</span>}
            </div>
            <br/>
            <br/>
            
            </>   
        )
    }
}
